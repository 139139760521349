import { useTranslation } from "react-i18next";
import { languageSelection } from "../../../../dynamicController";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const SettingDetails = ({
  selected,
  handleBack,
  handleChange,
  handleLang,
  handleSubmit,
  name,
  email /* , oldPassword*/,
  number,
  otp,
  showOtp,
  newPassword,
  language,
  loading,
  birthdayDate,
  handleBirthday
}) => {
  const { t } = useTranslation();

  const renderPage = () => {
    if (selected === "name") {
      return (
        <form id="name-form" onSubmit={handleSubmit}>
          <div className="form-group">
            {STORETYPE !== "cleanpro" ? <label htmlFor="name">{t("Name")}</label> : null}
            <input
              type="text"
              id="name"
              name="name"
              placeholder={t("Name")}
              value={name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mt-5">
            <input
              id="change-name"
              type="submit"
              style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
              value={STORETYPE === "cleanpro" ? t("Update name") : t("SUBMIT")}
              className="btn big-button form-control"
            />
          </div>
        </form>
      );
    } else if (selected === "email") {
      return (
        <form id="email-form" onSubmit={handleSubmit}>
          <div className="form-group">
            {STORETYPE !== "cleanpro" ? <label htmlFor="email">{t("Email")}</label> : null}
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t("Email")}
              value={email}
              onChange={handleChange}
              required
            />
          </div>

          {
            showOtp ? (
              <div>
                {STORETYPE !== "cleanpro" ? <label htmlFor="otp">{t("OTP")}</label> : null}
                <input
                  type="number"
                  id="otp"
                  name="otp"
                  placeholder={t("OTP")}
                  value={otp}
                  onChange={handleChange}
                  required
                />
                <small>{t("Our One-Time Password (OTP) emails might end up in your spam or junk folder. We kindly ask you to take a moment to check your spam mailbox.")}</small>
              </div>
            ) : null
          }

          <div className="mt-5">
            <input
              id="change-email"
              type="submit"
              style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
              value={STORETYPE === "cleanpro" ? t("Update email address") : t("SUBMIT")}
              className="btn big-button form-control"
            />
          </div>
        </form>
      );
    } else if (selected === "number") {
      return (
        <form id="number-form" onSubmit={handleSubmit}>
          <div className="form-group">
            {STORETYPE !== "cleanpro" ? <label htmlFor="number">{t("Phone Number")}</label> : null}
            <input
              type="number"
              id="number"
              name="number"
              placeholder={t("Phone Number")}
              value={number}
              onChange={handleChange}
              required
            />
          </div>

          {
            showOtp ? (
              <div>
                {STORETYPE !== "cleanpro" ? <label htmlFor="otp">{t("OTP")}</label> : null}
                <input
                  type="number"
                  id="otp"
                  name="otp"
                  placeholder={t("OTP")}
                  value={otp}
                  onChange={handleChange}
                  required
                />
              </div>
            ) : null
          }

          <div className="mt-5">
            <input
              id="change-phone-number"
              type="submit"
              style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
              value={STORETYPE === "cleanpro" ? t("Update phone number") : t("SUBMIT")}
              className="btn big-button form-control"
            />
          </div>
        </form>
      );
    } else if (selected === "password") {
      return (
        <form id="password-form" onSubmit={handleSubmit}>
          {/* <div className="form-group">
                        <label htmlFor="oldPassword">Old Password</label>
                        <input type="text" id="oldPassword" name="oldPassword" placeholder="" value={oldPassword} onChange={handleChange} required />
                    </div> */}

          <div className="form-group">
            {STORETYPE !== "cleanpro" ? <label htmlFor="newPassword">{t("New Password")}</label> : null}
            <input
              type="text"
              id="newPassword"
              name="newPassword"
              placeholder={t("New Password")}
              value={newPassword}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mt-5">
            {loading ? (
              <div className="text-center">
                <div
                  className={STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"}
                  role="status"
                >
                  <span className="sr-only">Loading ...</span>
                </div>
              </div>
            ) : (
              <input
                id="change-password"
                type="submit"
                style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
                value={STORETYPE === "cleanpro" ? t("Change password") : t("SUBMIT")}
                className="btn big-button form-control"
              />
            )}
          </div>
        </form>
      );
    } else if (selected === "language") {
      return (
        <form id="language-form" onSubmit={handleSubmit}>
          <div className="form-group">
            {STORETYPE !== "cleanpro" ? (
              <>
                <label htmlFor="language">{t("Language")}</label>
                <select
                  name="language"
                  id="language"
                  className="browser-default form-select"
                  value={language}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    {t("Select language")}
                  </option>
                  {
                    languageSelection.map((language, index) => {
                      return (
                        <option value={language.value} key={index}>{language.title}</option>
                      )
                    })
                  }
                </select>
              </>
            ) : (
              <>
                {
                  languageSelection.map((lang, index) => {
                    return (
                      <input
                        key={index}
                        onClick={handleLang}
                        id="language"
                        name={lang.value}
                        type="button"
                        value={lang.title}
                        className={`d-block full-width my-3 btn big-button ${language === lang.value ? "highlight-btn" : "lowlight-btn"}`}
                      />
                    )
                  })
                }
              </>
            )}
          </div>

          <div className="mt-5">
            <input
              id="change-language"
              type="submit"
              style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
              value={STORETYPE === "cleanpro" ? t("Change language") : t("SUBMIT")}
              className="btn big-button form-control"
            />
          </div>
        </form>
      );
    } else if (selected === "birthday") {
      return (
        <form id="birthday-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="date"
              id="birthdayDate"
              name="birthdayDate"
              placeholder={t("Select your birth date")}
              value={birthdayDate}
              onChange={handleBirthday}
              required
            />
          </div>

          <div className="mt-5">
            <input
              id="update-birthday"
              type="submit"
              style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
              value={STORETYPE === "cleanpro" ? t("Update date of birth") : t("SUBMIT")}
              className="btn big-button form-control"
            />
          </div>
        </form>
      )
    }
  };

  return (
    <div className="m-0 p-0">
      <div id="user-header">
        <h5>
          <i
            onClick={(e) => handleBack()}
            className="bi bi-chevron-left me-3"
          ></i>
          <strong>
            {
              STORETYPE === "cleanpro" ?
                selected === "name" ? t("Update name") :
                  selected === "number" ? t("Update phone number") :
                    selected === "email" ? t("Update email address") :
                      selected === "password" ? t("Change password") :
                        selected === "language" ? t("Change language") :
                          selected === "birthday" ? t("Update birth date") : ""
                : t("Back")
            }
          </strong>
        </h5>
      </div>

      <div className="p-5">{renderPage()}</div>
    </div>
  );
};

export default SettingDetails;
