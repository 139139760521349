import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { changeLanguage } from "../../../../../redux/actions/userActions";
import { languageSelection } from "../../../../../dynamicController";

const WashupCheck = ({ change, showButton, laundroLite, redirectPaymentPage, language, changeLanguage }) => {

    const { t } = useTranslation();

    return (
        <div className="px-5 mt-3">
            <div className="text-center mb-4">
                <h3 className="font-weight-bold m-0 p-0">{t("Join as our")}</h3>
                <h1 className="font-weight-bold m-0 p-0 washup-blue-text">{t("Loyalty Member")}</h1>
                <p>{t("now to get more reward")}</p>
            </div>

            <div>
                {
                    !laundroLite ? (
                        <button
                            id="register"
                            onClick={() => change("register")}
                            className="big-button form-control btn mb-2"
                        >
                            {t("REGISTER")}
                        </button>
                    ) : null
                }
                {
                    !laundroLite ? (
                        <button
                            id="login"
                            onClick={() => change("login")}
                            className="big-button form-control btn mb-2"
                        >
                            {t("LOGIN")}
                        </button>
                    ) : null
                }
                {
                    showButton ? (
                        <Link
                            id="skip-to-payment"
                            to="/user/payment"
                            className="big-button-alt form-control btn mb-2"
                            onClick={redirectPaymentPage}
                        >
                            - {t("SKIP TO PAYMENT")} -
                        </Link>
                    ) : null
                }

                <div className="language">
                    <select
                        name="language"
                        id="language"
                        className="browser-default form-select"
                        value={language}
                        onChange={e => changeLanguage(e.target.value)}
                        required
                    >
                        {
                            languageSelection.map((language, index) => {
                                return (
                                    <option value={language.value} id={language.value} key={index}>
                                        {language.title}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        language: state.user.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (language) => dispatch(changeLanguage(language)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WashupCheck);