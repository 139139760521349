import { Pie } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  SubTitle
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle);

const VoucherGivenChart = ({ voucherChartData }) => {

    const { t } = useTranslation();

    let labels = [];
    let graphData = [];

    if (voucherChartData.vouchers.length > 0) {
        labels = voucherChartData.vouchers.map(voucher => t(voucher.id))
        graphData = voucherChartData.vouchers.map(data => data.totalVouchers)
    }

    const data = {
        labels: labels,
        datasets: [{
            data: graphData,
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(178, 255, 89)',
                'rgb(153, 102, 255)',
                'rgb(255, 159, 64)',
            ],
        }]
    };

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: t("Number of voucher given to users")
            },
            subtitle: {
                display: true,
                text: `${t("Total number of voucher given")}: ${graphData.reduce((a, b) => a + b, 0)}`
            }
        }
    };

    return (<Pie data={data} options={options} />);
}

export default VoucherGivenChart;