import { connect } from "react-redux";

const AboutUs = ({ history, operatorId }) => {
    return (
        <div id="agreement-page">
            <nav id="agreement-nav">
                <button onClick={e => history.goBack()} style={{ outline: "none", backgroundColor: "transparent", border: "none" }}><i className="bi bi-chevron-left"></i></button>
            </nav>

            {
                operatorId === "rblyrj9i59p2x036jnmjdsye" ? (
                    <div className="mt-4 mb-5 px-5">
                        <h3 style={{ color: "#1C75BC" }}>ABOUT US</h3>
                        <div style={{ textAlign: "justify" }}>
                            <p>Serviced Apartments Kota Kinabalu (Operated by Laksana Wawasan Sdn Bhd)</p>
                            <p>Citadines Waterfront Kota Kinabalu houses stylish, fully equipped rooms to suit every guest's needs. All living spaces come complete with a kitchenette, spacious bedroom, separate living and dining spaces, and other modern essentials.</p>
                            <p>Apart from these well-furnished serviced apartments in Kota Kinabalu, guests have easy access to a diverse range of excellent amenities and facilities, including the residents' lounge, swimming pool, gymnasium, launderette, and meeting rooms.</p>
                            <p>Strategically positioned within Sabah's capital city, the property enables guests to effortlessly reach a host of captivating destinations. From lively markets and  lavish shopping malls to serene city parks and even the Kota Kinabalu International Airport — every popular attraction is within easy reach.</p>
                            <p>For more information or room booking, click HERE to chat with us via WhatsApp!</p>
                            <p>Citadines Laundrette (Operated By Laksana Wawasan Sdn Bhd)</p>
                            <p>Guests can take advantage of our all-day access launderette facilities, complete with self-service washing machines and dryers to meet their needs.</p>
                            <p>HOURS: 24 hours</p>
                            <p>LOCATION: Level 6</p>
                            <p>Contact Us: enquiry.sabah@the-ascott.com</p>
                        </div>
                    </div>
                ) : operatorId === "mzlkx7olr2yuwskuulz83srx" ? (
                    <div className="mt-4 mb-5 px-5">
                        <h3 style={{ color: "#1C75BC" }}>ABOUT US</h3>
                        <div style={{ textAlign: "justify" }}>
                            <p>Welcome to our self-service laundry at Leo Leisure Hotel, conveniently located on the ground floor at Jalan Hang Kasturi, Kuala Lumpur. A joint venture between CDEX RESOURCES and Leo Leisure Hotel, we opened in mid-October 2024 to provide an easy laundry solution for hotel guests, tourists, and expats in the heart of the city. With 5 washers, 5 dryers, and a fully cashless payment system (e-wallets, online banking, and VISA/MASTERCARD), we’re the perfect stop for those visiting nearby hotspots like Petaling Street Market and Central Market. Fast, easy, and secure laundry at your fingertips.</p>
                        </div>

                        <h3 style={{ color: "#1C75BC" }}>CONTACT US</h3>
                        <div style={{ textAlign: "justify" }}>
                            <p>Our Self Service Laundry Shop:</p>
                            <p>Leo Leisure Hotel, Jalan Hang Kasturi, City Centre, 50000 KUALA LUMPUR</p>
                            <p>Website: <a href="https://www.leoleisure.com.my/">https://www.leoleisure.com.my/</a></p>
                            <p>Our Office:</p>
                            <p><a href="https://g.co/kgs/Pq2NgdC">1-12A, Blok Podium, Selangorku PR1MA Lakefront Homes, Jalan Persiaran, Cyber 11, 63000 CYBERJAYA</a></p>
                            <p>HP: +6017-4510612</p>
                            <p>Email: cdexresources@gmail.com</p>
                        </div>

                        <h3 style={{ color: "#1C75BC" }}>RETURN & REFUND POLICY</h3>
                        <div style={{ textAlign: "justify" }}>
                            <p>Thank you for choosing our self-service laundry shop. We strive to provide the best service, but in case of any issues, we’ve outlined our return and refund policy below for your reference.</p>
                        </div>

                        <ol>
                            <li>Laundry Service Issues:</li>
                            <div style={{ textAlign: "justify" }}>
                                <p>Incorrect or Damaged Items: If you believe that an item has been damaged or incorrectly washed, please contact us immediately. Our team will inspect the issue and determine if it was caused by a fault in the machine. If we find that the damage was due to machine malfunction or an error on our part, we will offer a full refund or provide a replacement service at no additional charge.</p>
                            </div>

                            <li>Refunds for Payment Errors:</li>
                            <div style={{ textAlign: "justify" }}>
                                <p>Payment Processing Issues: If you experience issues with our cashless payment system (e-wallet, online banking, VISA/MC) or were incorrectly charged for services, please notify us within 24 hours of your transaction. We will review the situation and, if necessary, issue a refund or correct the charge.</p>
                            </div>

                            <li>No Refunds for Change of Mind:</li>
                            <div style={{ textAlign: "justify" }}>
                                <p>Once laundry services are initiated (i.e., when you begin using the washers or dryers), we are unable to provide refunds for change of mind, as our services are non-reversible once in progress.</p>
                            </div>

                            <li>Lost or Unclaimed Items:</li>
                            <div style={{ textAlign: "justify" }}>
                                <p>We are not responsible for any lost or unclaimed items left behind in the laundry machines or premises. Please ensure to check all items before leaving.</p>
                            </div>

                            <li>Contact for Issues:</li>
                            <div style={{ textAlign: "justify" }}>
                                <p>If you encounter any problems or have concerns, please reach out to our CARELINE contact details provided at the shop. We will do our best to resolve the matter promptly and fairly.</p>
                            </div>

                            <li>Policy Changes:</li>
                            <div style={{ textAlign: "justify" }}>
                                <p>We reserve the right to update or modify this policy at any time. Any changes will be posted within the premises and on our official communications. </p>
                            </div>
                        </ol>
                        <p style={{ textAlign: "justify" }}>We appreciate your understanding and your business!</p>

                        <h3 style={{ color: "#1C75BC" }}>PRIVACY POLICY</h3>
                        <div style={{ textAlign: "justify" }}>
                            <p>At our self-service laundry shop located at Leo Leisure Hotel, we value your privacy and are committed to safeguarding the personal information you provide. This Privacy Policy outlines how we collect, use, store, and protect your data when using our services.</p>
                        </div>

                        <ol>
                            <b><li>Information We Collect</li></b>
                            <div style={{ textAlign: "justify" }}>
                                <p>When you use our self-service laundry services, we may collect the following types of information:</p>
                                <ul style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}><b>Personal Information</b>: Your name, phone number, and email address (if you provide them when using our services).</li>
                                    <li style={{ listStyleType: "disc" }}><b>Payment Information</b>: Details related to your cashless payments, including e-wallets, online banking, and credit card information (processed securely via third-party payment systems).</li>
                                    <li style={{ listStyleType: "disc" }}><b>Usage Data</b>: Information related to your use of our laundry machines, such as the time of use, the duration, and the payment amount.</li>
                                </ul>
                            </div>

                            <b><li className="pt-3">How We Use Your Information</li></b>
                            <div style={{ textAlign: "justify" }}>
                                <p>We use the collected information to:</p>
                                <ul style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}>Process your laundry services and payments.</li>
                                    <li style={{ listStyleType: "disc" }}>Improve the functionality and user experience of our self-service machines.</li>
                                    <li style={{ listStyleType: "disc" }}>Communicate with you about service-related matters (e.g., issues with payments or services).</li>
                                    <li style={{ listStyleType: "disc" }}>Provide customer support in case of inquiries, issues, or refunds.</li>
                                    <li style={{ listStyleType: "disc" }}>Comply with legal or regulatory requirements.</li>
                                </ul>
                            </div>

                            <b><li className="pt-3">Payment Security</li></b>
                            <div style={{ textAlign: "justify" }}>
                                <p>All payment transactions are processed through secure, third-party payment gateways. We do not store any sensitive payment information (e.g., credit card details) on our systems. Your payment details are encrypted and handled in accordance with industry-standard security protocols.</p>
                            </div>

                            <b><li className="pt-3">Data Retention</li></b>
                            <div style={{ textAlign: "justify" }}>
                                <p>We will retain your personal and transaction data only as long as necessary for the purposes outlined in this Privacy Policy or as required by law. After this period, we will securely delete or anonymize your data.</p>
                            </div>

                            <b><li className="pt-3">Sharing of Your Information</li></b>
                            <div style={{ textAlign: "justify" }}>
                                <p>We do not sell, rent, or trade your personal information to third parties. We may share your data with:</p>
                                <ul style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}><b>Third-Party Service Providers</b>: Trusted vendors and service providers who assist with payment processing, customer support, and technical operations.</li>
                                    <li style={{ listStyleType: "disc" }}><b>Legal Requirements</b>: If required by law or to protect our legal rights, we may disclose your information to government authorities or legal entities.</li>
                                </ul>
                            </div>

                            <b><li className="pt-3">Your Rights</li></b>
                            <div style={{ textAlign: "justify" }}>
                                <p>You have the right to:</p>
                                <ul style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}>Request access to the personal data we hold about you.</li>
                                    <li style={{ listStyleType: "disc" }}>Correct or update any inaccurate or incomplete personal data.</li>
                                    <li style={{ listStyleType: "disc" }}>Request the deletion of your data, subject to any legal or contractual obligations we may have.</li>
                                    <li style={{ listStyleType: "disc" }}>Withdraw your consent at any time if you have previously agreed to the processing of your personal data.</li>
                                </ul>
                            </div>

                            <b><li className="pt-3">Security of Your Data</li></b>
                            <div style={{ textAlign: "justify" }}>
                                <p>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction. While we strive to ensure the security of your data, no method of electronic storage or transmission is 100% secure.</p>
                            </div>

                            <b><li className="pt-3">Changes to This Privacy Policy</li></b>
                            <div style={{ textAlign: "justify" }}>
                                <p>We may update this Privacy Policy from time to time. Any changes will be posted on our premises or communicated to you through our official channels. Please review the policy periodically for any updates.</p>
                            </div>

                            <b><li className="pt-3">Contact Us</li></b>
                            <div style={{ textAlign: "justify" }}>
                                <p>If you have any questions or concerns about our Privacy Policy or how we handle your personal data, please contact us directly at the shop or through the contact details provided.</p>
                            </div>
                        </ol>
                        <p>By using our self-service laundry services, you agree to the terms outlined in this Privacy Policy. Thank you for trusting us with your information!</p>
                    </div>
                ) : null
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        operatorId: state.user.operatorId,
    };
};

export default connect(mapStateToProps, null)(AboutUs);