import { KIOSK_LOGIN, REMOVE_TOKEN, SAVE_KIOSK_OUTLETID } from './type';
import axios from 'axios';

export const kioskLogin = (data) => {
    return {
        type: KIOSK_LOGIN,
        payload: data
    };
};

export const kioskLogout = () => {
    return {
        type: REMOVE_TOKEN
    };
};

export const checkToken = () => (dispatch, getState) => {
    if (getState().kiosk.token && getState().kiosk.outletId) {
        axios.post('/api/kiosk/checkToken', { token: getState().kiosk.token, outletId: getState().kiosk.outletId })
            .then(res => dispatch({ type: KIOSK_LOGIN, payload: res.data }))
            .catch(err => {
                if (err.response.data.error === "Invalid Token") {
                    dispatch({ type: REMOVE_TOKEN });
                }
            });
    }
};

export const kioskSaveOutletId = (data) => {
    return {
        type: SAVE_KIOSK_OUTLETID,
        payload: data
    };
};