import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { currency } from "../../../dynamicController";
import Navigator from "./Navigation";

const TransDetails = ({ user }) => {
  const history = useHistory();
  const location = useLocation();
  const [transaction, setTransaction] = useState(null);
  const [reload, setReload] = useState(null);
  const [transfer, setTransfer] = useState(null);
  const [point, setPoint] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!location.state || !user) {
      history.push('/')
    } else if (location.state.transaction) {
      setTransaction(location.state.transaction)
    } else if (location.state.reload) {
      setReload(location.state.reload)
    } else if (location.state.transfer) {
      setTransfer(location.state.transfer)
    } else if (location.state.point) {
      setPoint(location.state.point)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderDate = (date, filter) => {
    const ascMonth = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const thisDate = new Date(date);

    let hour24 = "";
    let hour = "";

    if (thisDate.getUTCHours() >= 16) {
      hour24 = thisDate.getUTCHours() + 8 - 24;
    } else {
      hour24 = thisDate.getUTCHours() + 8;
    }

    if (hour24 > 12) {
      hour = hour24 - 12;
      if (hour.toString().length === 1) {
        hour = `0${hour}`;
      }
    } else {
      hour = hour24;
      if (hour.toString().length === 1) {
        hour = `0${hour}`;
      }
    }

    const minute =
      thisDate.getUTCMinutes().toString().length === 1
        ? `0${thisDate.getUTCMinutes()}`
        : thisDate.getUTCMinutes().toString();

    const day =
      thisDate.getDate().toString().length === 1
        ? `0${thisDate.getDate()}`
        : thisDate.getDate().toString();

    const month = ascMonth[thisDate.getMonth()];

    const year = thisDate.getFullYear();
    if (filter) {
      return `${month} ${day}`;
    } else {
      return `${hour}:${minute} ${hour24 >= 12 ? "pm" : "am"}, ${day} ${month} ${year}`;
    }
  };

  return (
    <div id="trans-details-page">
      <div id="user-header">
        <h5>
          <strong onClick={() => history.goBack()}>
            <i className="bi bi-chevron-left me-3"></i> {t("Back")}
          </strong>
        </h5>
      </div>
      <div className="px-4 py-4 payment-section">
        <h1 className="font-weight-bold">
          {transaction ? transaction.method === "TOKEN" ? `-${transaction.payment_amount} ${t("Credits")}` : `${currency(user.country)}${transaction.payment_amount}` : null}
          {reload ? `+${reload.token} ${t("Credits")}` : null}
          {transfer ? transfer.amount > 0 ? `+${transfer.amount} ${t("Credits")}` : `${transfer.amount} ${t("Credits")}` : null}
          {point ? `${point.amount} ${user.loyalty_point_name}` : null}
        </h1>
        <div className="py-3 px-2 justify-content-between d-flex">
          <p className="t-light-g">{t("Total")}</p>
          {transaction ? transaction.method === "TOKEN" ? <p>{transaction.amount} {t("Credits")}</p> : <p>{currency(user.country)} {transaction.amount}</p> : null}
          {reload ? <p>{currency(user.country)} {reload.amount}</p> : null}
          {transfer ? transfer.amount > 0 ? <p>+{transfer.amount} {t("Credits")}</p> : <p>{transfer.amount} {t("Credits")}</p> : null}
          {point ? `${point.amount} ${user.loyalty_point_name}` : null}
        </div>
        <div className="py-3 px-2 justify-content-between d-flex">
          <p className="t-light-g">{t("Transaction Type")}</p>
          <p>{transaction && transaction.transaction_type === "Package" ? t("Drop n Go Service") : transaction ? t("Transaction") : reload ? t("Credit Topup") : transfer ? t("Credit Transfer") : point ? t(point.type) : "-"}</p>
        </div>
        {
          transaction && transaction.machine_name ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Machine")}</p>
              <p>{transaction.machine_name} ({transaction.machine_type})</p>
            </div>
          ) : null
        }
        {
          transaction ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Outlet")}</p>
              <p>{transaction.outlet.outlet_fullname}</p>
            </div>
          ) : null
        }
        {
          transaction || reload ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Payment Method")}</p>
              <p>{transaction ? transaction.method === "TOKEN" ? t("Credits") : transaction.method : reload && reload.method ? reload.method : "-"}</p>
            </div>
          ) : null
        }
        <div className="py-3 px-2 justify-content-between d-flex">
          <p className="t-light-g">{t("Date/Time")}</p>
          <p>{transaction ? renderDate(transaction.createdAt) : reload ? renderDate(reload.createdAt) : transfer ? renderDate(transfer.createdAt) : point ? renderDate(point.createdAt) : null}</p>
        </div>
        <div className="py-3 px-2 justify-content-between d-flex">
          <p className="t-light-g">{t("Reference ID")}</p>
          <p>{transaction ? transaction.id : reload ? reload.id : point && point.transactionId ? point.transactionId : point && point.tokenPurchaseHistoryId ? point.tokenPurchaseHistoryId : point && point.giveawayId ? point.giveawayId : "-"}</p>
        </div>
        {
          transaction || reload ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Status")}</p>
              <p>{transaction ? t(transaction.status) : reload ? t(reload.status) : transfer ? t("Success") : "-"}</p>
            </div>
          ) : null
        }
        {
          transaction && transaction.transaction_type === "Package" && transaction.status === "Success" ? (
            <div className="text-center pt-3">
              <button className="btn btn-primary big-button" onClick={() => history.push("/user/dng/pack/details", { transaction })}>{t("Pack Details")}</button>
            </div>
          ) : null
        }
      </div>
      <Navigator />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(TransDetails);
