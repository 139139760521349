import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { changeNav } from "../../../../../redux/actions/userActions";

import navIcon from "../../../../../assets/images/icons/icn-hamburger.png";

import washupLogo from "../../../../../assets/images/logo/washupLogo1.png";
import washupProfile from "../../../../../assets/images/icons/washup-profile.png";
import washupEtoken from "../../../../../assets/images/icons/washup-etoken.png";
// import washupTopupButton from "../../../../../assets/images/icons/washup-topup-button.png";
// import washupTransferButton from "../../../../../assets/images/icons/washup-transfer-button.png";

const WashupHeader = ({ user, setToggled, changeNav }) => {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div id="user-header">
            <div className="d-flex justify-content-between">
                <img
                    style={{ width: "200px", objectFit: "contain" }}
                    src={washupLogo}
                    alt=""
                />
                <div>
                    <img
                        style={{ width: "28px", objectFit: "contain" }}
                        onClick={(e) => setToggled(true)}
                        src={navIcon}
                        alt=""
                    />
                </div>
            </div>
            <div className="mt-3 d-flex align-items-center">
                <div>
                    {user && user.profilePicture ? (
                        <img
                            src={user ? user.profilePicture : ""}
                            alt=""
                            className="img-fluid"
                            style={{ width: "46px", height: "46px", borderRadius: "50%" }}
                        />
                    ) : (
                        <img
                            style={{ width: "46px", objectFit: "contain" }}
                            src={washupProfile}
                            alt=""
                        />
                    )}
                </div>
                <div className="mx-2">
                    <p className="m-0 p-0">{user ? user.name : null}</p>
                    <p className="m-0 p-0">{user ? user.memberId : null}</p>
                    <div className="d-flex m-0 p-0">
                        {
                            user?.loyalty_point ? (
                                <p className="m-0 p-0 me-3">
                                    <small>
                                        {user?.loyalty_point_name} :{" "}
                                        <span style={{ color: "#ffd109" }}>
                                            <strong>{user?.point}</strong>
                                        </span>
                                    </small>
                                </p>
                            ) : null
                        }
                        <p className="m-0 p-0">
                            <small>
                                {t("Total Wash")} :{" "}
                                <span style={{ color: "#ffd109" }}>
                                    <strong>{user ? user.totalWash : 0}</strong>
                                </span>
                            </small>
                        </p>
                        <p className="m-0 p-0 ms-3">
                            <small>
                                {t("Total Dry")} :{" "}
                                <span style={{ color: "#ffd109" }}>
                                    <strong>{user ? user.totalDry : 0}</strong>
                                </span>
                            </small>
                        </p>
                    </div>
                </div>
            </div>

            <div id="user-info-container">
                <div className="d-flex justify-content-around">
                    <div className="align-content-center">
                        <img src={washupEtoken} alt="token" />
                    </div>
                    <div className="align-content-center">
                        <p className="m-0 p-0">{t("Balance")}</p>
                        <p className="m-0 p-0"><strong><span style={{ fontSize: "20px" }}>{user?.token}</span> {t("Token")}</strong></p>
                    </div>
                    {
                        user?.reload ? (
                            <div id="reload" className="reload-button align-content-center" onClick={() => { changeNav("reload"); history.push("/user/reload") }}>
                                {/* <img src={washupTopupButton} alt="" className="img-fluid" /> */}
                                <span>{t("Topup")}</span><br /><i className="bi bi-plus-lg"></i>
                            </div>
                        ) : null
                    }
                    {
                        user?.transfer ? (
                            <div id="transfer" className="reload-button align-content-center" onClick={() => { changeNav("transfer"); history.push("/user/transfer") }}>
                                {/* <img src={washupTransferButton} alt="" className="img-fluid" /> */}
                                <span>{t("Transfer")}</span><br /><i className="bi bi-arrow-left-right"></i>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeNav: (nav) => dispatch(changeNav(nav)),
    };
};

export default connect(null, mapDispatchToProps)(WashupHeader);