import { useTranslation } from "react-i18next";
import { currency } from "../../../../dynamicController";
import { connect } from "react-redux";
import greyBear from "../../../.././assets/images/icons/icn-creditGrey.png";
import washupToken from "../../../../assets/images/icons/washup-etoken.png";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const ReloadList = ({ data, loading, handleSubmit, user }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div
        className="text-center"
        style={{ width: "100%", height: "100%", margin: 0, padding: "5px 0" }}
      >
        <div
          style={{
            width: "80px",
            height: "80px",
            fontWeight: "bold",
            fontSize: "36px",
            margin: "10px",
            padding: 0,
          }}
          className={
            process.env.REACT_APP_STORETYPE === "cuci"
              ? "mt-5 spinner-border text-warning"
              : "mt-5 spinner-border text-primary"
          }
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else {
    if (!data || !data.length) {
      return (
        <div className="text-center mt-5">
          <h3> - {t("No Promotions")} - </h3>
        </div>
      );
    } else {
      const renderList = () =>
        data.sort((a, b) => a.price - b.price).map((x) => {
          return (
            <li
              className={`list-group-item ${STORETYPE === "cleanpro" && "px-4 py-3"}`}
              key={x.id}
            >
              <div className="d-flex justify-content-between align-items-center">
                {STORETYPE === "cleanpro" ? (
                  <>
                    <h5 className="ml-1" style={{ marginTop: ".4em" }}>
                      {currency(user?.country)}{parseFloat(x.price).toFixed(2)}
                    </h5>
                    {
                      x.voucherSettingId && x.voucher_setting?.voucher_eligible ? <p className="m-0 p-0"><small>{t("FREE")} {x.voucher_setting.voucher_type === "Flat" ? currency(user?.country) : null}{x.voucher_setting.voucher_amount}{x.voucher_setting.voucher_type === "Rate" ? "%" : null} {t("Voucher")}</small></p> : null
                    }
                    {
                      x.point_eligible && user?.loyalty_point ? <p className="m-0 p-0"><small>{t("FREE")} {x.point_amount} {user?.loyalty_point_name}</small></p> : null
                    }
                    <button
                      style={{
                        textTransform: "lowercase",
                        backgroundColor: "#004fc1",
                        borderRadius: "20px",
                        minWidth: "160px",
                      }}
                      onClick={(e) => handleSubmit(x)}
                      className="m-0 font-weight-bold btn btn-primary px-5"
                    >
                      {x.token} {t("credits")}
                    </button>
                  </>
                ) : STORETYPE === "washup" ? (
                  <>
                    <div className="d-flex align-items-center">
                      <img src={washupToken} alt="token" className="pe-2" />
                      <h5 className="m-0 p-0">
                        {x.token} {t("e-Token")}
                      </h5>
                    </div>
                    {
                      x.voucherSettingId && x.voucher_setting?.voucher_eligible ? <p className="m-0 p-0"><small>{t("FREE")} {x.voucher_setting.voucher_type === "Flat" ? currency(user?.country) : null}{x.voucher_setting.voucher_amount}{x.voucher_setting.voucher_type === "Rate" ? "%" : null} {t("Voucher")}</small></p> : null
                    }
                    {
                      x.point_eligible && user?.loyalty_point ? <p className="m-0 p-0"><small>{t("FREE")} {x.point_amount} {user?.loyalty_point_name}</small></p> : null
                    }
                    <button
                      onClick={(e) => handleSubmit(x)}
                      className="m-0 font-weight-bold btn big-button"
                    >
                      {currency(user?.country)} {parseFloat(x.price).toFixed(2)}
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <h5 className="m-0 p-0">
                      {x.token} {t("e-Tokens")}
                    </h5>
                    {
                      x.voucherSettingId && x.voucher_setting?.voucher_eligible ? <p className="m-0 p-0"><small>{t("FREE")} {x.voucher_setting.voucher_type === "Flat" ? currency(user?.country) : null}{x.voucher_setting.voucher_amount}{x.voucher_setting.voucher_type === "Rate" ? "%" : null} {t("Voucher")}</small></p> : null
                    }
                    {
                      x.point_eligible && user?.loyalty_point ? <p className="m-0 p-0"><small>{t("FREE")} {x.point_amount} {user?.loyalty_point_name}</small></p> : null
                    }
                    <button
                      onClick={(e) => handleSubmit(x)}
                      className="m-0 font-weight-bold btn btn-primary"
                    >
                      {currency(user?.country)} {parseFloat(x.price).toFixed(2)}
                    </button>
                  </>
                )}
              </div>
            </li>
          );
        });

      return (
        <div
          className="list-group"
          style={{
            maxHeight: "52vh",
            overflowX: "hidden",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        >
          {STORETYPE === "cleanpro" && (
            <div>
              <img
                className="ml-1 mb-2"
                style={{ width: "24px" }}
                src={greyBear}
                alt=""
              />
              <span className="font-weight-bold ml-1">{t("Select amount to topup")}</span>
            </div>
          )}
          {
            STORETYPE === "washup" ? (
              <div className="stamp-component-cp">
                <h4>{t("Topup")} <span><i className="bi bi-plus-lg" style={{ color: "#CCB300" }}></i></span></h4>
                <hr style={{ backgroundColor: "#002E4D", height: "2px" }} />
                {renderList()}
              </div>
            ) : renderList()
          }
        </div>
      );
    }
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(ReloadList);
