import { currency } from "../../../../../dynamicController";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// cleanpro icon
import foldWasher from "../../../../../assets/images/machines/icn-washSticker.png";
import dryerIcon from "../../../../../assets/images/machines/icn-dryer.png";
import washerIcon from "../../../../../assets/images/machines/icn-washer.png";

// washup icon
import washupWasher from "../../../../../assets/images/machines/washup-washer.png";
import washupDryer from "../../../../../assets/images/machines/washup-dryer.png";
import washupStamp from "../../../../../assets/images/machines/washup-stamp.png";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const CleanproStamping = ({ user, stampVoucherSetting }) => {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div>
            {
                user.stamp ? (
                    <div className="stamp-component-cp">
                        <div className="d-flex">
                            {
                                STORETYPE === "washup"
                                    ?
                                    <p className="px-1 my-2" style={{ fontSize: "18px", fontWeight: 500 }}>
                                        <Trans i18nKey="Collect 10 e-Stamp to earn FREE Voucher">
                                            Collect 10 e-Stamp to earn FREE Voucher
                                        </Trans>
                                    </p>
                                    : stampVoucherSetting
                                        ? stampVoucherSetting.voucher_type === "Flat"
                                            ?
                                            <p className="px-1 my-2" style={{ fontSize: "18px", fontWeight: 500 }}>
                                                <Trans i18nKey="Collect 10 e-stamps to earn FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }})" count={stampVoucherSetting.voucher_amount} >
                                                    Collect 10 e-stamps to earn FREE {{ voucherAmount: stampVoucherSetting.voucher_amount }} credits voucher (worth {{ currency: currency(user.country) }}{{ voucherAmount: stampVoucherSetting.voucher_amount }})
                                                </Trans>
                                            </p>
                                            :
                                            <p className="px-1 my-2" style={{ fontSize: "18px", fontWeight: 500 }}>
                                                <Trans i18nKey="Collect 10 e-stamps to earn FREE {{ voucherAmount }}% voucher (worth {{ voucherAmount }}%)">
                                                    Collect 10 e-stamps to earn FREE {{ voucherAmount: stampVoucherSetting.voucher_amount }}% voucher (worth {{ voucherAmount: stampVoucherSetting.voucher_amount }}%)
                                                </Trans>
                                            </p>
                                        : null
                            }
                            <img
                                src={STORETYPE === "cleanpro" ? foldWasher : washupStamp}
                                style={{ width: "56px" }}
                                className="fit-contain pl-1"
                                alt=""
                            />
                        </div>
                        {
                            user.combinedSetting ? (
                                <div className="py-3">
                                    <div className="d-flex">
                                        <img src={STORETYPE === "cleanpro" ? dryerIcon : washupDryer} style={{ width: "40px" }} alt="" />
                                        <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")} / {t("Dry")}</p>
                                        <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.combinedStamps}/10</p>
                                    </div>
                                    <progress id="progress-bar" value={user.combinedStamps} max="10"></progress>
                                </div>
                            ) : user.specificStamp === "both" ? (
                                <div>
                                    <div className="py-3">
                                        <div className="d-flex">
                                            <img src={STORETYPE === "cleanpro" ? washerIcon : washupWasher} style={{ width: "40px", padding: "3px" }} alt="" />
                                            <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")}</p>
                                            <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.washerStamp}/10</p>
                                        </div>
                                        <progress id="progress-bar" value={user.washerStamp} max="10"></progress>
                                    </div>
                                    <div className="py-3">
                                        <div className="d-flex">
                                            <img src={STORETYPE === "cleanpro" ? dryerIcon : washupDryer} style={{ width: "40px" }} alt="" />
                                            <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")}</p>
                                            <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.dryerStamp}/10</p>
                                        </div>
                                        <progress id="progress-bar" value={user.dryerStamp} max="10"></progress>
                                    </div>
                                </div>
                            ) : user.specificStamp === "washerOnly" ? (
                                <div className="py-3">
                                    <div className="d-flex">
                                        <img src={STORETYPE === "cleanpro" ? washerIcon : washupWasher} style={{ width: "40px", padding: "3px" }} alt="" />
                                        <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")}</p>
                                        <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.washerStamp}/10</p>
                                    </div>
                                    <progress id="progress-bar" value={user.washerStamp} max="10"></progress>
                                </div>
                            ) : user.specificStamp === "dryerOnly" ? (
                                <div className="py-3">
                                    <div className="d-flex">
                                        <img src={STORETYPE === "cleanpro" ? dryerIcon : washupDryer} style={{ width: "40px" }} alt="" />
                                        <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")}</p>
                                        <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.dryerStamp}/10</p>
                                    </div>
                                    <progress id="progress-bar" value={user.dryerStamp} max="10"></progress>
                                </div>
                            ) : null
                        }
                        <p
                            id="stamp-details"
                            onClick={() => STORETYPE === "cleanpro" ? history.push("/user/moreDetails") : history.push("/user/news/stamp")}
                            className="pb-1"
                            style={{ textAlign: "right", color: "#004fc1" }}
                        >
                            {t("More details")} &gt;
                        </p>
                    </div>
                ) : null
            }
        </div>
    )
}

export default CleanproStamping;