import { staffLogout } from "../../../redux/actions/staffActions";
import { changeLanguage } from "../../../redux/actions/userActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { staffRedirectUrl, laundroText, languageSelection } from "../../../dynamicController";
import { useTranslation } from "react-i18next";

const Topbar = ({ handleToggle, logout, redirectUrl, userType, changeLanguage, language }) => {

	const history = useHistory();
	const { t } = useTranslation();

	return (
		<nav className="navbar">
			<div>
				<button onClick={() => handleToggle()}>
					<i style={{ fontSize: "20px" }} className="bi bi-list"></i>
				</button>
			</div>

			<div className="d-flex">
				<div className="m-auto pe-2">
					<select
						name="language"
						id="language"
						className="form-select"
						value={language}
						onChange={e => changeLanguage(e.target.value)}
						required
					>
						{
							languageSelection.map((language, index) => {
								return (
									<option value={language.value} id={language.value} key={index}>
										{language.title}
									</option>
								)
							})
						}
					</select>
				</div>

				<div className="dropdown">
					<button type="button" className="dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
						<i style={{ fontSize: "40px", display: "inline-block" }} className="bi bi-person-circle"></i>
					</button>

					<div style={{ transform: "translate(-100px, 0px)" }} className="dropdown-menu m-0" aria-labelledby="dropdownMenu">
						<div className="dropdown-item">
							<button
								onClick={() => {
									logout();
									history.push("/staff/login");
								}}
								className="btn"
							>
								{t("Logout")}
							</button>
						</div>

						<a href={redirectUrl ? `${redirectUrl}?userType=${userType}&language=${language}` : staffRedirectUrl()} className="dropdown-item py-3" style={{ color: "black" }}>
							{t("Back To")} {laundroText()}
						</a>
					</div>
				</div>
			</div>
		</nav>
	);
};

const mapStateToProps = state => {
	return {
		redirectUrl: state.staff.redirectUrl,
		userType: state.staff.userType,
		language: state.user.language,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(staffLogout()),
		changeLanguage: (language) => dispatch(changeLanguage(language))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
