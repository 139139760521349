import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CleanproStamping from "../stamp/CleanproStamping";
import NewsSlider from "../NewsSlider";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const CleanproDashboaardBody = ({ user, renderTimer, history }) => {

  const [stampVoucherSetting, setStampVoucherSetting] = useState(null)

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      if (user.stamp) {
        if (user.combinedSetting) {
          let voucherSetting = user.voucher_setting.filter(setting => setting.type === "Combined Stamp")[0]
          if (voucherSetting && voucherSetting.voucher_eligible) {
            setStampVoucherSetting(voucherSetting)
          }
        } else if (user.specificStamp === "washerOnly") {
          let voucherSetting = user.voucher_setting.filter(setting => setting.type === "Washer Stamp")[0]
          if (voucherSetting && voucherSetting.voucher_eligible) {
            setStampVoucherSetting(voucherSetting)
          }
        } else if (user.specificStamp === "dryerOnly") {
          let voucherSetting = user.voucher_setting.filter(setting => setting.type === "Dryer Stamp")[0]
          if (voucherSetting && voucherSetting.voucher_eligible) {
            setStampVoucherSetting(voucherSetting)
          }
        } else {
          let washerStampVoucherSetting = user.voucher_setting.filter(setting => setting.type === "Washer Stamp")[0]
          let dryerStampVoucherSetting = user.voucher_setting.filter(setting => setting.type === "Dryer Stamp")[0]
          if (washerStampVoucherSetting && washerStampVoucherSetting.voucher_eligible) {
            setStampVoucherSetting(washerStampVoucherSetting)
          } else if (dryerStampVoucherSetting && dryerStampVoucherSetting.voucher_eligible) {
            setStampVoucherSetting(dryerStampVoucherSetting)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className="mt-5">
      {
        user.loyalty_point && user.point_expiry ? (
          <p className="text-center pb-3"><b>{user.expirePoint}</b> {user.loyalty_point_name} {t("expiring on")} <b>{new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</b></p>
        ) : null
      }

      {
        renderTimer
      }

      <CleanproStamping user={user} stampVoucherSetting={stampVoucherSetting} />

      <div className="mt-5">
        <h5 className="ml-2 dashboard-promotion-text">
          <strong>{STORETYPE === "washup" ? t("Promotion") : t("Promotions")}</strong>
        </h5>
        <div className="ml-1 pb-5">
          <NewsSlider page="dashboard" history={history} />
        </div>
      </div>
    </div>
  )
}

export default CleanproDashboaardBody;