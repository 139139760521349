import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Select } from 'antd';

import { saveCountry, saveOperatorId } from "../../../redux/actions/userActions";
import { fetchTheme } from "../../../redux/actions/themeAction";

import { useTranslation } from 'react-i18next';

const mySwal = withReactContent(Swal);

const CheckOperator = ({ saveOperatorId, fetchTheme, setOutletId, saveCountry, setLaundroLite }) => {

    const [loading, setLoading] = useState(true);
    const [outletList, setOutletList] = useState([]);
    const [outletData, setOutletData] = useState([]);
    const [outlet, setOutlet] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        axios.get('/api/nonuser/getOutletList')
            .then(res => {
                let outletOptions = []
                for (let i = 0; i < res.data.data.length; i++) {
                    let outletObject = {
                        value: res.data.data[i].id,
                        label: res.data.data[i].outlet_fullname
                    }
                    outletOptions.push(outletObject)
                }
                setOutletData(res.data.data)
                setOutletList(outletOptions)
                setLoading(false)
            })
            .catch(err => {
                mySwal.fire(t("Error"), t(err.response.data.error), "error")
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        if (!outlet) {
            mySwal.fire(t("Error"), t("Please fill in all the required fields before submit."), "error");
        } else {
            setLoading(true)
            const selectedOutlet = outletData.filter(o => o.id === outlet)[0];

            if (process.env.REACT_APP_STORETYPE !== "cuci" && process.env.REACT_APP_STORETYPE !== "cleanpro") {
                fetchTheme({ operatorCode: selectedOutlet.adminId });
            }

            axios
                .post("/api/nonuser/checkLaundroLite", { operatorId: selectedOutlet.adminId })
                .then(res => {
                    saveCountry(res.data.data.country)
                    if (!res.data.data.member_payment) {
                        setLaundroLite(true)
                    }
                    setOutletId(selectedOutlet.id)
                    saveOperatorId(selectedOutlet.adminId);
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                })
        }
    }

    return (
        <div className="px-5 mt-5">
            <div className="text-center mb-4">
                <h3 className="font-weight-bold m-0 p-0">{t("WELCOME")}</h3>
            </div>

            <div>
                <form onSubmit={handleSubmit}>
                    <div className="px-4">
                        <div>
                            <label className="form-label" htmlFor="outlet" id="outlet-label">
                                {t("Outlet")}
                            </label>
                            <Select
                                className="select-input"
                                style={{ width: "100%" }}
                                placeholder={t("Select an outlet")}
                                options={outletList}
                                showSearch
                                optionFilterProp="label"
                                onChange={(value) => setOutlet(value)}
                            />
                        </div>

                        <div className="mt-5">
                            {
                                loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <input id="selectOutlet" type="submit" value={t("Submit")} className="big-button form-control btn" />
                                )
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        saveOperatorId: id => dispatch(saveOperatorId(id)),
        fetchTheme: data => dispatch(fetchTheme(data)),
        saveCountry: (country) => dispatch(saveCountry(country)),
    };
};

export default connect(null, mapDispatchToProps)(CheckOperator);