import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import i18n from "i18next";
import {
  InputGroup,
  Button,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { updateUser, changeLanguage } from "../../../redux/actions/userActions";
import SettingDetails from "./setting/SettingDetails";
import profile from "../../../assets/images/icons/icn-profile.png";
import camera from "../../../assets/images/icons/icn-change-profile-icon.png";
import { languageSelection } from "../../../dynamicController";

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;
class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selected: "",
      name: "",
      email: "",
      number: "",
      // oldPassword : "",
      newPassword: "",
      language: "",
      copy: false,
      birthdayMonth: "",
      birthdayDay: "",
      birthdayYear: "",
      birthdayDate: "",
      otp: "",
      showOtp: ""
    };
  }

  copyMemberId = () => {
    navigator.clipboard.writeText(`https://${window.location.hostname}/welcome?operatorCode=${this.props.operatorId}&referralCode=${this.props.user.memberId}`);
    this.setState({ copy: true });
    setTimeout(() => { this.setState({ copy: false }); }, 1000);
  };

  handleImage = (e) => {
    const file = e.target.files[0];

    const imageRegex = /image\//g;
    const jpgRegex = /.jpg/g;
    const pngRegex = /.png/g;
    const jpegRegex = /.jpeg/g;

    if (imageRegex.test(file.type)) {
      if (
        jpgRegex.test(file.name) ||
        pngRegex.test(file.name) ||
        jpegRegex.test(file.name)
      ) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", this.props.user.id);
        this.setState({ loading: true });
        axios
          .post("/api/user/setting/profilePicture", formData, {
            headers: {
              "Content-Type": "application/json",
              "auth-token": this.props.token,
            },
          })
          .then((res) => {
            this.setState({ loading: false });
            this.props.updateUser({ type: "image", data: res.data.data });
            mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
          })
          .catch((err) => {
            this.setState({ loading: false });
            mySwal.fire("Error", err.response.data.error, "error");
          });
      } else {
        mySwal.fire(
          i18n.t("Info"),
          i18n.t("Please only use png, jpg or jpeg file extension type"),
          "info"
        );
      }
    } else {
      mySwal.fire(
        i18n.t("Info"),
        i18n.t("Please only use image type file"),
        "info"
      );
    }
  };

  handleBack = () => this.setState({ selected: "" });

  handleChange = (e) =>
    this.setState({ ...this.state, [e.target.id]: e.target.value });

  handleLang = (e) =>
    this.setState({ ...this.state, [e.target.id]: e.target.name });

  handleBirthday = (e) => {
    const ascMonth = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    this.setState({
      ...this.state,
      birthdayDate: e.target.value,
      birthdayDay: new Date(e.target.value).getDate(),
      birthdayMonth: ascMonth[new Date(e.target.value).getMonth()],
      birthdayYear: new Date(e.target.value).getFullYear()
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const id = this.props.user.id;
    if (e.target.id === "name-form") {
      const { name } = this.state;
      // console.log({id , name});
      if (this.props.user.name === name) {
        this.setState({
          ...this.state,
          selected: "",
          name: "",
        });
      } else {
        axios
          .post(
            "/api/user/setting/userName",
            { id, name },
            {
              headers: {
                "Content-Type": "application/json",
                "auth-token": this.props.token,
              },
            }
          )
          .then((res) => {
            this.setState({
              ...this.state,
              selected: "",
              name: "",
            });
            this.props.updateUser({ type: "name", data: res.data.data });
            // console.log(res.data);
            if (STORETYPE === "cleanpro") {
              mySwal.fire({
                title: i18n.t("Success"),
                text: i18n.t(res.data.message),
                icon: "success",
                confirmButtonText: i18n.t("Okay"),
                confirmButtonColor: "#004FC1",
                customClass: {
                  confirmButton: "cleanpro-swal-btn"
                }
              })
            } else {
              mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
            }
          })
          .catch((err) => {
            this.setState({
              ...this.state,
              selected: "",
              name: "",
            });
            mySwal.fire("Error", err.response.data.error, "error");
          });
      }
    } else if (e.target.id === "email-form") {
      const { email, otp } = this.state;
      // console.log({ id , email });
      if (this.props.user.email === email && this.props.user.email_verified) {
        this.setState({
          ...this.state,
          selected: "",
          email: "",
          otp: "",
          showOtp: false
        });
      } else {
        axios
          .post("/api/user/setting/email", { id, email, otp }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
          .then((res) => {
            if (res.data.message && res.data.data) {
              this.setState({
                ...this.state,
                selected: "",
                email: "",
                otp: "",
                showOtp: false
              });
              this.props.updateUser({ type: "email", data: res.data.data });
              if (STORETYPE === "cleanpro") {
                mySwal.fire({
                  title: i18n.t("Success"),
                  text: i18n.t(res.data.message),
                  icon: "success",
                  confirmButtonText: i18n.t("Okay"),
                  confirmButtonColor: "#004FC1",
                  customClass: {
                    confirmButton: "cleanpro-swal-btn"
                  }
                })
              } else {
                mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
              }
            } else {
              this.setState({
                ...this.state,
                showOtp: true
              });
            }
          })
          .catch((err) => {
            mySwal.fire("Error", err.response.data.error, "error");
          });
      }
    } else if (e.target.id === "number-form") {
      const { number, otp } = this.state;

      if (this.props.user.number === number &&  this.props.user.verified) {
        this.setState({
          ...this.state,
          selected: "",
          number: "",
          otp: "",
          showOtp: false
        });
      } else {
        axios
          .post("/api/user/setting/number", { id, number, otp }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
          .then((res) => {
            if (res.data.message && res.data.data) {
              this.setState({
                ...this.state,
                selected: "",
                number: "",
                otp: "",
                showOtp: false
              });
              this.props.updateUser({ type: "number", data: res.data.data });
              if (STORETYPE === "cleanpro") {
                mySwal.fire({
                  title: i18n.t("Success"),
                  text: i18n.t(res.data.message),
                  icon: "success",
                  confirmButtonText: i18n.t("Okay"),
                  confirmButtonColor: "#004FC1",
                  customClass: {
                    confirmButton: "cleanpro-swal-btn"
                  }
                })
              } else {
                mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
              }
            } else {
              this.setState({
                ...this.state,
                showOtp: true
              });
            }
          })
          .catch((err) => {
            mySwal.fire("Error", err.response.data.error, "error");
          });
      }
    } else if (e.target.id === "password-form") {
      const { /*oldPassword , */ newPassword } = this.state;
      if (newPassword.length < 6) {
        mySwal.fire(
          i18n.t("Info"),
          i18n.t("Password must be at least 6 characters"),
          "info"
        );
      } else {
        // console.log({ id , oldPassword , newPassword });
        this.setState({
          ...this.state,
          loading: true,
        });
        axios
          .post(
            "/api/user/setting/password",
            { id, newPassword },
            {
              headers: {
                "Content-Type": "application/json",
                "auth-token": this.props.token,
              },
            }
          )
          .then((res) => {
            // console.log(res.data);
            this.setState({
              ...this.state,
              selected: "",
              // oldPassword : "",
              newPassword: "",
              loading: false,
            });
            if (STORETYPE === "cleanpro") {
              mySwal.fire({
                title: i18n.t("Success"),
                text: i18n.t(res.data.message),
                icon: "success",
                confirmButtonText: i18n.t("Okay"),
                confirmButtonColor: "#004FC1",
                customClass: {
                  confirmButton: "cleanpro-swal-btn"
                }
              })
            } else {
              mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
            }
          })
          .catch((err) => {
            this.setState({
              ...this.state,
              // oldPassword : "",
              newPassword: "",
              loading: false,
            });
            mySwal.fire("Error", err.response.data.error, "error");
          });
      }
    } else if (e.target.id === "language-form") {
      const { language } = this.state;

      if (this.props.language === language) {
        this.setState({
          ...this.state,
          selected: "",
          language: "",
        });
      } else {
        this.props.changeLanguage(language);
        if (STORETYPE === "cleanpro") {
          mySwal.fire({
            title: i18n.t("Success"),
            text: i18n.t("Language has updated successfully!"),
            icon: "success",
            confirmButtonText: i18n.t("Okay"),
            confirmButtonColor: "#004FC1",
            customClass: {
              confirmButton: "cleanpro-swal-btn"
            }
          })
        } else {
          mySwal.fire(i18n.t("Success"), i18n.t("Language has updated successfully!"), "success");
        }
        this.setState({
          ...this.state,
          selected: "",
          language: "",
        });
      }
    } else if (e.target.id === "birthday-form") {
      const { birthdayMonth, birthdayDay, birthdayYear } = this.state;

      axios
        .post("/api/user/setting/birthday", { id, birthdayMonth, birthdayDay, birthdayYear },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": this.props.token,
            },
          }
        )
        .then((res) => {
          this.setState({
            ...this.state,
            selected: "",
            birthdayMonth: "",
            birthdayDay: "",
            birthdayYear: "",
            birthdayDate: ""
          });
          this.props.updateUser({ type: "birthday", data: res.data.data });
          if (STORETYPE === "cleanpro") {
            mySwal.fire({
              title: i18n.t("Success"),
              text: i18n.t(res.data.message),
              icon: "success",
              confirmButtonText: i18n.t("Okay"),
              confirmButtonColor: "#004FC1",
              customClass: {
                confirmButton: "cleanpro-swal-btn"
              }
            })
          } else {
            mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
          }
        })
        .catch((err) => {
          mySwal.fire("Error", err.response.data.error, "error");
        });
    }
  };

  share = () => {
    navigator
      .share({
        title: "Referral Code Sharing",
        text: `Hi, you are invited to register an account with my referral code(${this.props.user.memberId}).`,
        url: `https://${window.location.hostname}/welcome?operatorCode=${this.props.operatorId}&referralCode=${this.props.user.memberId}`,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  };

  render() {
    if (this.props.user) {
      return (
        <div id="setting-page">
          <div
            id="setting-main"
            className={this.state.selected ? "selected" : ""}
          >
            <div id="user-header">
              <h5>
                <i
                  onClick={(e) => this.props.history.push("/")}
                  className="bi bi-chevron-left me-3"
                ></i>{" "}
                <strong>{i18n.t("User Settings")}</strong>
              </h5>
            </div>

            <div id="user-body">
              {STORETYPE === "cleanpro" ? (
                <div
                  id="user-info-container"
                  className="box-shadow-card"
                  style={{
                    display: "flex",
                    margin: "0 8vw 15px",
                    padding: "15px 10px",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    {this.state.loading ? (
                      <div className="m-auto">
                        <div
                          className={STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"}
                          role="status"
                        >
                          <span className="sr-only">Loading ...</span>
                        </div>
                      </div>
                    ) : this.props.user.profilePicture ? (
                      <img
                        src={this.props.user.profilePicture}
                        alt="pp"
                        className="img-fluid"
                        style={{ width: "58px", height: "58px", borderRadius: "50%" }}
                      />
                    ) : (
                      <img
                        style={{ width: "58px", objectFit: "contain" }}
                        src={profile}
                        alt=""
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      id="profilePicture"
                      name="profilePicture"
                      onChange={this.handleImage}
                    />
                    <img
                      style={{
                        width: "22px",
                        objectFit: "contain",
                        position: "absolute",
                        bottom: "0",
                        right: "0",
                      }}
                      src={camera}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      width: "70%",
                      marginLeft: "20px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h2 className="font-weight-bold d-inline m-0">
                      {this.props.user.name}
                    </h2>
                    <p className="d-inline t-light-g small-t">
                      {i18n.t("User ID")}: {this.props.user.memberId}
                    </p>
                  </div>
                </div>
              ) : (
                <div id="edit-pp">
                  <div id="pp-container">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        borderRadius: "50%",
                        overflow: "hidden",
                        display: "flex",
                        alignContent: "center",
                        textAlign: "center",
                      }}
                    >
                      {this.state.loading ? (
                        <div className="m-auto">
                          <div
                            className={STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"}
                            role="status"
                          >
                            <span className="sr-only">Loading ...</span>
                          </div>
                        </div>
                      ) : this.props.user.profilePicture ? (
                        <img
                          src={this.props.user.profilePicture}
                          alt="pp"
                          className="img-fluid"
                        />
                      ) : (
                        <i id="temp-pp" className="bi bi-person-fill"></i>
                      )}
                    </div>

                    <input
                      type="file"
                      accept="image/*"
                      id="profilePicture"
                      name="profilePicture"
                      onChange={this.handleImage}
                    />

                    <div id="faimage-container">
                      <i className="fas bi-card-image"></i>
                    </div>
                  </div>

                  <p style={{ margin: "25px 0 0 0", fontWeight: "bold" }}>
                    {i18n.t("Profile Picture")}
                  </p>
                </div>
              )}

              {this.props.user.referral && STORETYPE !== "cleanpro" ? (
                <div className="px-5">
                  <h3 className="text-center">{i18n.t("Referral Code")}:</h3>
                  <div style={{ display: "flex" }}>
                    <InputGroup className="mb-3">
                      <FormControl
                        value={this.props.user.memberId}
                        readOnly
                        style={{
                          border: "1px solid #6c757d",
                          textAlign: "center",
                          borderTopLeftRadius: "25px",
                          borderBottomLeftRadius: "25px",
                        }}
                      />
                      <OverlayTrigger
                        placement="top"
                        show={this.state.copy}
                        overlay={<Tooltip>{i18n.t("Copied!")}</Tooltip>}
                      >
                        <Button
                          variant="outline-secondary"
                          style={{
                            zIndex: "1",
                            width: "43px",
                            height: "43px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                          }}
                          onClick={() => this.copyMemberId()}
                        >
                          <i className="bi bi-stickies"></i>
                        </Button>
                      </OverlayTrigger>
                    </InputGroup>
                    <Button
                      className="ms-2"
                      variant="outline-secondary"
                      style={{
                        zIndex: "1",
                        width: "43px",
                        height: "43px",
                        borderRadius: "25px",
                      }}
                      onClick={() => this.share()}
                    >
                      <i className="bi bi-share"></i>
                    </Button>
                  </div>
                </div>
              ) : null}

              <ul id="setting-list" className="list-group">
                <li
                  className="list-group-item"
                  onClick={() =>
                    this.setState({
                      selected: "name",
                      name: this.props.user.name,
                    })
                  }
                >
                  <div>
                    <p className="m-0 font-weight-bold">{i18n.t("Name")}</p>
                    <p className="m-0">{this.props.user.name}</p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i className="bi bi-chevron-right"></i>
                  </div>
                </li>

                <li
                  className="list-group-item"
                  onClick={() =>
                    this.setState({
                      selected: "number",
                      number: this.props.user.number,
                      otp: "",
                      showOtp: false
                    })
                  }
                >
                  <div>
                    <p className="m-0 font-weight-bold">{i18n.t("Phone Number")}</p>
                    <p className="m-0">{this.props.user.number}</p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i className="bi bi-chevron-right"></i>
                  </div>
                </li>

                <li
                  className="list-group-item"
                  onClick={() =>
                    this.setState({
                      selected: "email",
                      email: this.props.user.email,
                      otp: "",
                      showOtp: false
                    })
                  }
                >
                  <div>
                    <p className="m-0 font-weight-bold">{i18n.t("Email")}</p>
                    <p className="m-0">{this.props.user.email}</p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i className="bi bi-chevron-right"></i>
                  </div>
                </li>

                {
                  STORETYPE === "cleanpro" ?
                    this.props.user.birthday.month && this.props.user.birthday.day && this.props.user.birthday.year ? (
                      <li className="list-group-item">
                        <div>
                          <p className="m-0 font-weight-bold">
                            {i18n.t("Date of birth")}
                          </p>
                          <p className="m-0">{this.props.user.birthday.day} {this.props.user.birthday.month.substring(0, 3)} {this.props.user.birthday.year}</p>
                        </div>
                      </li>
                    ) : (
                      <li
                        className="list-group-item"
                        onClick={() => this.setState({ selected: "birthday" })}
                      >
                        <div>
                          <p className="m-0 font-weight-bold">{i18n.t("Date of birth")}</p>
                          <p className="m-0">{i18n.t("Enter your date of birth")}</p>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <i className="bi bi-chevron-right"></i>
                        </div>
                      </li>
                    ) : null
                }

                <li
                  className="list-group-item"
                  onClick={() => this.setState({ selected: "password" })}
                >
                  <div>
                    <p className="m-0 font-weight-bold">
                      {i18n.t("Change Password")}
                    </p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i className="bi bi-chevron-right"></i>
                  </div>
                </li>

                <li
                  className="list-group-item"
                  onClick={() =>
                    this.setState({
                      selected: "language",
                      language: this.props.language,
                    })
                  }
                >
                  <div>
                    <p className="m-0 font-weight-bold">
                      {i18n.t("Change Language")}
                    </p>
                    <p className="m-0">
                      {languageSelection.filter(language => language.value === this.props.language).length > 0 ? languageSelection.filter(language => language.value === this.props.language)[0].title : ""}
                    </p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i className="bi bi-chevron-right"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div
            id="setting-detail"
            className={this.state.selected ? "selected" : ""}
          >
            <SettingDetails
              selected={this.state.selected}
              handleBack={this.handleBack}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              name={this.state.name}
              email={this.state.email}
              number={this.state.number}
              otp={this.state.otp}
              showOtp={this.state.showOtp}
              // oldPassword={this.state.oldPassword}
              newPassword={this.state.newPassword}
              language={this.state.language}
              loading={this.state.loading}
              handleLang={this.handleLang}
              birthdayDay={this.state.birthdayDay}
              birthdayMonth={this.state.birthdayMonth}
              birthdayYear={this.state.birthdayYear}
              birthdayDate={this.state.birthdayDate}
              handleBirthday={this.handleBirthday}
            />
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    token: state.user.token,
    language: state.user.language,
    operatorId: state.user.operatorId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data)),
    changeLanguage: (language) => dispatch(changeLanguage(language)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
