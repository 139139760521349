import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dateFormat, timeFormat } from "../../../../../dynamicController";

import io from 'socket.io-client';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const QrCode = () => {

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const [qrUrl, setQrUrl] = useState("");
    const [qrSrc, setQrSrc] = useState("");
    const [loading, setLoading] = useState(false);
    const [isIos/*, setIos*/] = useState(/iPhone|iPad|iPod/i.test(navigator.userAgent))

    useEffect(() => {
        if (location.state && location.state.url) {
            setQrUrl(location.state.url)
            setQrSrc(location.state.url)

            setLoading(true);
            axios
                .post("/api/user/downloadQr", { url: location.state.url }, { responseType: 'blob' })
                .then(response => {
                    const blob = response.data
                    const downloadUrl = URL.createObjectURL(blob);
                    setQrSrc(downloadUrl)

                    if (!isIos) {
                        const a = document.createElement('a');
                        a.href = downloadUrl;
                        a.download = `qr_${dateFormat(new Date())}_${timeFormat(new Date())}.png`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        // URL.revokeObjectURL(downloadUrl);
                    }

                    setLoading(false);
                })
                .catch(err => {
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                    setLoading(false);
                });
        }

        if (location.state && location.state.transactionId) {
            const socket = io({ auth: { transactionId: location.state.transactionId } })
            socket.on("duitnowPayment", (transactionId) => {
                if (location.state.kiosk) {
                    history.push("/kiosk")
                } else {
                    history.push("/thankyou")
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadQR = () => {
        setLoading(true);
        axios
            .post("/api/user/downloadQr", { url: qrUrl }, { responseType: 'blob' })
            .then(response => {
                const blob = response.data
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = `qr_${dateFormat(new Date())}_${timeFormat(new Date())}.png`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(downloadUrl);
                setLoading(false);
            })
            .catch(err => {
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
                setLoading(false);
            });
    }

    return (
        <div id="setting-page" className="user-faq">
            <div id="setting-main">
                <div id="user-header">
                    <h5><i onClick={e => { location.state.kiosk ? history.push("/kiosk") : history.push("/") }} className="bi bi-chevron-left me-3"></i> <strong>{t("QR code")}</strong></h5>
                </div>

                <div id="user-body">
                    {
                        qrUrl ? (
                            <div className="pt-3 pb-3">
                                <h4 className="ps-3">{t("Your QR code")}:</h4>
                                <div className="text-center">
                                    <img src={qrSrc} alt="duitnow-qr" />
                                </div>
                                <div className="text-center">
                                    {/* <a className="btn btn-primary" href={`${process.env.REACT_APP_API_URL}/api/user/downloadQr?url=${qrUrl}`} download={`qr_${dateFormat(new Date())}_${timeFormat(new Date())}.png`}>{t("Download")}</a> */}
                                    {
                                        loading ? (
                                            <div className="text-center">
                                                <div className={process.env.REACT_APP_STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"} role="status">
                                                    <span className="sr-only">Loading ...</span>
                                                </div>
                                            </div>
                                        ) : <button className="btn btn-primary" onClick={() => downloadQR()}>{t("Download")}</button>
                                    }
                                </div>
                                {isIos ? <p className="text-center px-2 mt-2">{t('For iOS users, press and hold the image, then choose "Add to Photos" or "Save in Photos" from the menu.')}</p> : null}
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default QrCode;