import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Loading from "../components/Loading";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CSVLink } from "react-csv";
import { Tooltip, OverlayTrigger, Modal, Form } from 'react-bootstrap';
import { currency, dateFormat, timeFormat, emailLogo } from "../../../dynamicController";
import i18n from "i18next";
import rewardlogo from '../../../assets/images/rewardlogo.png';
import refundlogo from '../../../assets/images/refundlogo.png';

const mySwal = withReactContent(Swal);

class UserDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			data: null,
			loading: false,
			error: null,
			phone: "",
			giveawayModal: false,
			voucherModal: false,
			tokenModal: false,
			stampModal: false,
			pointModal: false,
			userCountry: "",
			userId: "",
			remark: "Reward",
			tokenAmount: 0,
			pointAmount: 0,
			washerStamp: 0,
			dryerStamp: 0,
			title: "",
			description: "",
			duration: "",
			type: "Flat",
			amount: "",
			washerUse: false,
			dryerUse: false,
			packageUse: false,
			adminMessage: "You have received your compensation. You may login your account to check it.",
			adminEmail: "",
			adminCompanyName: "",
			adminCompanyPhoneNo: "",
			showEmailPreview: false,
			selectedUserName: "",
			emailVerified: false,
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.admin || !this.props.admin.isAuthenticated || !this.props.admin.admin.user_detail) {
				this.props.history.push("/admin/dashboard");
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleChange = e => {

		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	};

	memberDetailsReport = (memberId, userId) => {
		this.props.history.push("/admin/user/details/memberDetails", { memberId, userId });
	}

	handleSubmit = e => {
		e.preventDefault();

		const { operatorId } = this.props.admin.admin;
		const { phone } = this.state;

		if (operatorId && phone) {
			this.setState({
				...this.state,
				loading: true,
			});
			axios
				.post("/api/admin/setting/getUsers", { operatorId, phone: phone.replace(/ /g, '') }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
				.then(res => {
					this.setState({
						...this.state,
						loading: false,
						data: res.data.data
					});
				})
				.catch(err => {
					this.setState({ ...this.state, loading: false });
					mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
				});
		}
	}

	openGiveawayModal = (userId, country, userName, emailVerified) => {
		this.setState({
			userId,
			userCountry: country,
			remark: "Reward",
			tokenAmount: 0,
			pointAmount: 0,
			washerStamp: 0,
			dryerStamp: 0,
			title: "",
			description: "",
			duration: "",
			type: "Flat",
			amount: "",
			washerUse: false,
			dryerUse: false,
			packageUse: false,
			giveawayModal: true,
			selectedUserName: userName,
			emailVerified,
		});
	};

	// GIVE VOUCHER
	giveVoucher = (e) => {
		e.preventDefault();
		const { title, description, amount, washerUse, dryerUse, packageUse, adminMessage, emailVerified } = this.state;
		const { operatorId } = this.props.admin.admin;

		if (!title || !description || !amount || parseFloat(amount) <= 0) {
			mySwal.fire(i18n.t("Missing Field"), i18n.t("Please fill in all the required field. (Title, description, and amount must be more than 0)"), "info");
		} else if (!washerUse && !dryerUse && !packageUse) {
			mySwal.fire(i18n.t("Checkbox required"), i18n.t("Must select voucher can be used for washer, dryer or package"), "info");
		} else if (!emailVerified && adminMessage.length > 130) {
			mySwal.fire(i18n.t("Error"), i18n.t("SMS will send to user, please enter the message less than 130 characters"), "error");
		} else {
			this.setState({ loading: true });
			const sendThis = {
				id: this.state.userId,
				rewardBy: operatorId,
				rewardType: "Voucher",
				title,
				description,
				duration: this.state.duration,
				type: this.state.type,
				amount,
				washerUse,
				dryerUse,
				packageUse,
				remark: this.state.remark,
				role: "operator",
				adminMessage,
				adminEmail: this.state.adminEmail,
				adminCompanyName: this.state.adminCompanyName,
				adminCompanyPhoneNo: this.state.adminCompanyPhoneNo,
			};

			axios
				.post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
				.then((res) => {
					const { operatorId } = this.props.admin.admin;
					const { phone } = this.state;

					axios
						.post("/api/admin/setting/getUsers", { operatorId, phone: phone.replace(/ /g, '') }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
						.then(res => {
							this.setState({
								loading: false,
								data: res.data.data,
								voucherModal: false,
							});
						})
						.catch(err => {
							this.setState({ loading: false });
							mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
						});
				})
				.catch((err) => {
					this.setState({ loading: false });
					mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
				});
		}
	};

	// GIVE STAMP
	giveStamp = (e) => {
		e.preventDefault();
		const { washerStamp, dryerStamp, adminMessage, emailVerified } = this.state;
		const { operatorId } = this.props.admin.admin;

		if (!parseInt(washerStamp) && !parseInt(dryerStamp)) {
			mySwal.fire(i18n.t("Error"), i18n.t("Please fill in at least one of the required fields"), "error");
		} else if (parseInt(washerStamp) < 0 || parseInt(dryerStamp) < 0) {
			mySwal.fire(i18n.t("Error"), i18n.t("The value must be more than 0"), "error");
		} else if (!emailVerified && adminMessage.length > 130) {
			mySwal.fire(i18n.t("Error"), i18n.t("SMS will send to user, please enter the message less than 130 characters"), "error");
		} else {
			this.setState({ loading: true });
			const sendThis = {
				id: this.state.userId,
				rewardBy: operatorId,
				washerStamp,
				dryerStamp,
				rewardType: "Stamp",
				remark: this.state.remark,
				role: "operator",
				adminMessage,
				adminEmail: this.state.adminEmail,
				adminCompanyName: this.state.adminCompanyName,
				adminCompanyPhoneNo: this.state.adminCompanyPhoneNo,
			};

			axios
				.post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
				.then((res) => {
					const { operatorId } = this.props.admin.admin;
					const { phone } = this.state;

					axios
						.post("/api/admin/setting/getUsers", { operatorId, phone: phone.replace(/ /g, "") }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
						.then((res) => {
							this.setState({
								loading: false,
								data: res.data.data,
								stampModal: false,
							});
						})
						.catch((err) => {
							this.setState({ loading: false });
							mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
						});
				})
				.catch((err) => {
					this.setState({ loading: false });
					mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
				});
		}
	};

	// GIVE POINT
	givePoint = (e) => {
		e.preventDefault();
		const { pointAmount, adminMessage, emailVerified } = this.state;
		const { operatorId } = this.props.admin.admin;

		if (parseInt(pointAmount) <= 0) {
			mySwal.fire(i18n.t("Error"), i18n.t("The value must be more than 0"), "error");
		} else if (!emailVerified && adminMessage.length > 130) {
			mySwal.fire(i18n.t("Error"), i18n.t("SMS will send to user, please enter the message less than 130 characters"), "error");
		} else {
			this.setState({ loading: true });
			const sendThis = {
				id: this.state.userId,
				rewardBy: operatorId,
				point: pointAmount,
				rewardType: "Point",
				remark: this.state.remark,
				role: "operator",
				adminMessage,
				adminEmail: this.state.adminEmail,
				adminCompanyName: this.state.adminCompanyName,
				adminCompanyPhoneNo: this.state.adminCompanyPhoneNo,
			};

			axios
				.post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
				.then((res) => {
					const { operatorId } = this.props.admin.admin;
					const { phone } = this.state;

					axios
						.post("/api/admin/setting/getUsers", { operatorId, phone: phone.replace(/ /g, "") }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
						.then((res) => {
							this.setState({
								loading: false,
								data: res.data.data,
								pointModal: false,
							});
						})
						.catch((err) => {
							this.setState({ loading: false });
							mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
						});
				})
				.catch((err) => {
					this.setState({ loading: false });
					mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
				});
		}
	};

	// GIVE TOKEN
	giveToken = (e) => {
		e.preventDefault();
		const { tokenAmount, adminMessage, emailVerified } = this.state;
		const { operatorId } = this.props.admin.admin;

		if (parseFloat(tokenAmount) <= 0) {
			mySwal.fire(i18n.t("Error"), i18n.t("Token value must be more than 0"), "error");
		} else if (!emailVerified && adminMessage.length > 130) {
			mySwal.fire(i18n.t("Error"), i18n.t("SMS will send to user, please enter the message less than 130 characters"), "error");
		} else {
			this.setState({ loading: true });
			const sendThis = {
				id: this.state.userId,
				rewardBy: operatorId,
				token: tokenAmount,
				rewardType: "Token",
				remark: this.state.remark,
				role: "operator",
				adminMessage,
				adminEmail: this.state.adminEmail,
				adminCompanyName: this.state.adminCompanyName,
				adminCompanyPhoneNo: this.state.adminCompanyPhoneNo,
			};

			axios
				.post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
				.then((res) => {
					const { operatorId } = this.props.admin.admin;
					const { phone } = this.state;

					axios
						.post("/api/admin/setting/getUsers", { operatorId, phone: phone.replace(/ /g, "") }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
						.then((res) => {
							this.setState({
								loading: false,
								data: res.data.data,
								tokenModal: false,
							});
						})
						.catch((err) => {
							this.setState({ loading: false });
							mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
						});
				})
				.catch((err) => {
					this.setState({ loading: false });
					mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
				});
		}
	};

	generateEmailPreview = () => {
		let subject = '';
		let mainMessage = '';

		if (this.state.remark === "Refund") {
			subject = 'We Apologize for the Inconvenience';
			mainMessage = `
					<div style="text-align: center; padding: 0; background-color: #F9FAFF; font-family: 'Helvetica Neue', Arial, sans-serif; color: #4A4A4A;">
						<div style="background-color: #34429A; padding: 20px; margin: 0;">
							<img src="${emailLogo()}" alt="Company Logo" style="width: 150px; margin: 0;">
						</div>
						<div style="background-color: #FFFFFF; padding: 0; border-radius: 8px;">
							<img src="${refundlogo}" alt="Money Logo" style="width: 100%; height: auto; margin: 0; display: block; margin-bottom: 20px;">
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left">Dear ${this.state.selectedUserName},</p>
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left">${this.state.adminMessage}</p>
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left">We sincerely apologize for any inconvenience or frustration this may have caused.</p>
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left">If you have any questions or require further assistance, please do not hesitate to contact our customer support team at ${this.state.adminCompanyPhoneNo} or ${this.state.adminEmail}.</p>
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left">Sincerely,</p>
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left"><strong>${this.state.adminCompanyName}</strong></p>
							<p style="font-size: 16px; margin-bottom: 5px; text-align: left"><a href="mailto:${this.state.adminEmail}" style="color: #34429A;">${this.state.adminEmail}</a></p>
							<p style="font-size: 16px; text-align: left"><a href="tel:${this.state.adminCompanyPhoneNo}" style="color: #34429A;">${this.state.adminCompanyPhoneNo}</a></p>
						</div>
					</div>
					<div style="text-align: center;">
						<a href="${process.env.REACT_APP_API_URL}" style="display: inline-block; background-color: #004aad; color: #ffffff; padding: 10px 20px; border-radius: 50px; text-align: center; text-decoration: none; font-size: 16px; font-family: Arial, sans-serif; font-weight: bold; border: none; cursor: pointer;">Login Here</a>
					</div>
					`;
		} else if (this.state.remark === "Reward") {
			subject = 'Congratulations!';
			mainMessage = `
					<div style="text-align: center; padding: 0; background-color: #F9FAFF; font-family: 'Helvetica Neue', Arial, sans-serif; color: #4A4A4A;">
						<div style="background-color: #34429A; padding: 20px; margin: 0;">
							<img src="${emailLogo()}" alt="Company Logo" style="width: 150px; margin: 0;">
						</div>
						<div style="background-color: #FFFFFF; padding: 0; border-radius: 8px;">
							<img src="${rewardlogo}" alt="Reward Image" style="width: 100%; height: auto; margin: 0; display: block; margin-bottom: 20px;">
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left">Dear ${this.state.selectedUserName},</p>
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left">${this.state.adminMessage}</p>
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left">If you have any questions or require further assistance, please do not hesitate to contact our customer support team at ${this.state.adminCompanyPhoneNo} or ${this.state.adminEmail}.</p>
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left">Sincerely,</p>
							<p style="font-size: 16px; margin-bottom: 20px; text-align: left"><strong>${this.state.adminCompanyName}</strong></p>
							<p style="font-size: 16px; margin-bottom: 5px; text-align: left"><a href="mailto:${this.state.adminEmail}" style="color: #34429A;">${this.state.adminEmail}</a></p>
							<p style="font-size: 16px; text-align: left"><a href="tel:${this.state.adminCompanyPhoneNo}" style="color: #34429A;">${this.state.adminCompanyPhoneNo}</a></p>
						</div>
					</div>
					<div style="text-align: center;">
						<a href="${process.env.REACT_APP_API_URL}" style="display: inline-block; background-color: #004aad; color: #ffffff; padding: 10px 20px; border-radius: 50px; text-align: center; text-decoration: none; font-size: 16px; font-family: Arial, sans-serif; font-weight: bold; border: none; cursor: pointer;">Login Here</a>
					</div>
					`;
		}

		return `
				<!DOCTYPE html>
				<html>
				<head>
					<meta charset="UTF-8">
					<meta name="viewport" content="width=device-width, initial-scale=1.0">
					<title>${subject}</title>
				</head>
				<body style="margin: 0; padding: 0;">
					${mainMessage}
				</body>
				</html>`;
	}

	toggleEmailPreview = () => {
		this.setState({ showEmailPreview: !this.state.showEmailPreview });
	};

	render() {
		let data = {
			columns: [
				{
					label: i18n.t("Action"),
					field: "action",
				},
				{
					label: i18n.t("Member Details Report"),
					field: "report",
					sort: "asc"
				},
				{
					label: i18n.t("Name"),
					field: "name",
					sort: "asc"
				},
				{
					label: i18n.t("Phone Number"),
					field: "number",
					sort: "asc"
				},
				{
					label: i18n.t("Birthday"),
					field: "birthday",
					sort: "asc"
				},
				{
					label: i18n.t("Age"),
					field: "age",
					sort: "asc"
				},
				{
					label: i18n.t("Email Address"),
					field: "email",
					sort: "asc"
				},
				{
					label: i18n.t("Member ID"),
					field: "memberId",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of vouchers collected")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Vouchers Acquired")}</span>
						</OverlayTrigger>
					),
					field: "totalVouchers",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total amount spent in washer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Total Washer Payment")} ({currency(this.props.admin?.admin?.country)})</span>
						</OverlayTrigger>
					),
					field: "washerPayment",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total amount spent in dryer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Total Dryer Payment")} ({currency(this.props.admin?.admin?.country)})</span>
						</OverlayTrigger>
					),
					field: "dryerPayment",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total amount spent in all transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Total Payment")} ({currency(this.props.admin?.admin?.country)})</span>
						</OverlayTrigger>
					),
					field: "totalPayment",
					sort: "asc"
				}
			],
			rows: []
		};

		let csv = [];

		if (this.state.data) {
			for (let i = 0; i < this.state.data.length; i++) {
				const user = this.state.data[i];
				const dataObject = {
					report: (
						<button type="button" className="btn btn-link" onClick={e => this.memberDetailsReport(user.memberId, user.id)}>
							{i18n.t("report")}
						</button>
					),
					action: (
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.openGiveawayModal(user.id, user.country, user.name, user.email_verified)}
						>
							{i18n.t("Reward")}
						</button>
					),
					name: user.name,
					number: user.number,
					birthday: user.birthday,
					age: user.age,
					email: user.email,
					memberId: user.memberId,
					washerStamp: user.washerStamp,
					dryerStamp: user.dryerStamp,
					totalStamps: user.totalStamps,
					totalVouchers: user.totalVouchers,
					washerPayment: user.washerPayment,
					dryerPayment: user.dryerPayment,
					totalPayment: user.totalPayment,
					token: /\./g.test(user.token.toString()) ? parseFloat(user.token).toFixed(2) : parseFloat(user.token),
					tokenPayment: user.tokenPayment,
					tokenPurchased: user.tokenPurchased,
					washerToken: user.washerToken,
					dryerToken: user.dryerToken,
					tokenUsed: user.tokenUsed,
					point: user.point
				};
				data.rows.push(dataObject);

				let csvObject = {
					name: this.state.data[i].name,
					number: `=""${this.state.data[i].number}""`,
					birthday: new Date(this.state.data[i].birthday).toLocaleDateString('en-GB'),
					age: this.state.data[i].age,
					email: this.state.data[i].email,
					memberId: this.state.data[i].memberId,
					washerStamp: this.state.data[i].washerStamp,
					dryerStamp: this.state.data[i].dryerStamp,
					totalStamps: this.state.data[i].totalStamps,
					totalVouchers: this.state.data[i].totalVouchers,
					washerPayment: this.state.data[i].washerPayment,
					dryerPayment: this.state.data[i].dryerPayment,
					totalPayment: this.state.data[i].totalPayment,
					token: this.state.data[i].token,
					tokenPayment: this.state.data[i].tokenPayment,
					tokenPurchased: this.state.data[i].tokenPurchased,
					washerToken: this.state.data[i].washerToken,
					dryerToken: this.state.data[i].dryerToken,
					tokenUsed: this.state.data[i].tokenUsed,
					point: this.state.data[i].point,
				}
				csv.push(csvObject);
			}
		}

		const csvData = {
			headers: [
				{ label: "Name", key: "name" },
				{ label: "Phone Number", key: "number" },
				{ label: "Birthday", key: "birthday" },
				{ label: "Age", key: "age" },
				{ label: "Email Address", key: "email" },
				{ label: "Member ID", key: "memberId" },
				{ label: "Vouchers Acquired", key: "totalVouchers" },
				{ label: `Total Washer Payment (${currency(this.props.admin?.admin?.country)})`, key: "washerPayment" },
				{ label: `Total Dryer Payment (${currency(this.props.admin?.admin?.country)})`, key: "dryerPayment" },
				{ label: `Total Payment (${currency(this.props.admin?.admin?.country)})`, key: "totalPayment" },
			],
			data: csv
		};

		if (this.props.admin?.admin?.loyalty_point) {
			data.columns = [
				...data.columns,
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Current point")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Point")}</span>
						</OverlayTrigger>
					),
					field: "point",
					sort: "asc"
				},
			];

			csvData.headers.push(
				{ label: "point", key: "point" },
			)
		}

		if (this.props.admin?.admin?.token) {
			data.columns = [
				...data.columns,
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Current e-Token")}
								</Tooltip>
							}
						>
							<span>{i18n.t("e-Tokens")}</span>
						</OverlayTrigger>
					),
					field: "token",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total amount spent for buying e-Token")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Bought e-Token using")} ({currency(this.props.admin?.admin?.country)})</span>
						</OverlayTrigger>
					),
					field: "tokenPayment",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of e-Token purchased")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Total e-Token Purchased (Tokens)")}</span>
						</OverlayTrigger>
					),
					field: "tokenPurchased",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of e-Token used in washer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Washer Payment (Tokens)")}</span>
						</OverlayTrigger>
					),
					field: "washerToken",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of e-Token used in dryer machine transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Dryer Payment (Tokens)")}</span>
						</OverlayTrigger>
					),
					field: "dryerToken",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of e-Token used in all the transactions")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Total Payment (Tokens)")}</span>
						</OverlayTrigger>
					),
					field: "tokenUsed",
					sort: "asc"
				}
			];

			csvData.headers.push(
				{ label: "e-Tokens", key: "token" },
				{ label: `Bought e-Token using (${currency(this.props.admin?.admin?.country)})`, key: "tokenPayment" },
				{ label: "Total e-Token Purchased (Tokens)", key: "tokenPurchased" },
				{ label: "Washer Payment (Tokens)", key: "washerToken" },
				{ label: "Dryer Payment (Tokens)", key: "dryerToken" },
				{ label: "Total Payment (Tokens)", key: "tokenUsed" },
			)
		}

		if (this.props.admin?.admin?.stamp) {
			data.columns = [
				...data.columns,
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of washer stamp collected currently")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Washer Stamp")}</span>
						</OverlayTrigger>
					),
					field: "washerStamp",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Number of dryer stamp collected currently")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Dryer Stamp")}</span>
						</OverlayTrigger>
					),
					field: "dryerStamp",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									{i18n.t("Total number of stamps collected")}
								</Tooltip>
							}
						>
							<span>{i18n.t("Total Stamps")}</span>
						</OverlayTrigger>
					),
					field: "totalStamps",
					sort: "asc"
				},
			];

			csvData.headers.push(
				{ label: "Washer Stamp", key: "washerStamp" },
				{ label: "Dryer Stamp", key: "dryerStamp" },
				{ label: "Total Stamps", key: "totalStamps" },
			)
		}

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						{this.state.loading ? (
							<Loading />
						) : (
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">{i18n.t("User Details")}</h5>
								</div>

								<div className="card-body">
									<form onSubmit={this.handleSubmit}>
										<div className="form-group">
											<label htmlFor="phone">{i18n.t("User Phone Number / Member ID / Email")}:</label>
											<input type="text" id="phone" name="phone" value={this.state.phone} onChange={this.handleChange} required placeholder={i18n.t("Example: 0123456789,A123456")} />
										</div>
										<input type="submit" value={i18n.t("SEARCH")} className="btn btn-primary form-control mb-1" />
									</form>

									<div className="text-center mt-5">
										<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
										<div className="d-grid gap-2 d-md-flex justify-content-md-end">
											<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`user_details_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
												{i18n.t("Download Report")}
											</CSVLink>
										</div>
									</div>

									{/* Giveaway Modal */}
									<Modal
										show={this.state.giveawayModal}
										onHide={() => this.setState({ giveawayModal: false })}
										keyboard={false}
										centered
									>
										<Modal.Header closeButton>
											<Modal.Title>{i18n.t("Reward Giveaway")}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div>
												<p>{i18n.t("Which type of reward you want to give to customer?")}</p>
												<div className='d-flex justify-content-around'>
													<button
														type="button"
														className="btn btn-primary"
														onClick={() => this.setState({ giveawayModal: false, voucherModal: true })}
													>
														{i18n.t("Voucher")}
													</button>
													{this.props.admin?.admin?.stamp && (
														<button
															type="button"
															className="btn btn-primary"
															onClick={() => this.setState({ giveawayModal: false, stampModal: true })}
														>
															{i18n.t("Stamp")}
														</button>
													)}
													{this.props.admin?.admin?.token && (
														<button
															type="button"
															className="btn btn-primary"
															onClick={() => this.setState({ giveawayModal: false, tokenModal: true })}
														>
															{i18n.t("e-Token")}
														</button>
													)}
													{this.props.admin?.admin?.loyalty_point && (
														<button
															type="button"
															className="btn btn-primary"
															onClick={() => this.setState({ giveawayModal: false, pointModal: true })}
														>
															{i18n.t("Point")}
														</button>
													)}
												</div>
											</div>
										</Modal.Body>
									</Modal>

									{/* Voucher Modal */}
									<Modal
										show={this.state.voucherModal}
										onHide={() => this.setState({ voucherModal: false })}
										keyboard={false}
										centered
									>
										<Modal.Header closeButton>
											<Modal.Title>{i18n.t("VOUCHER")}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div>
												<form onSubmit={this.giveVoucher}>
													<div className="text-left mt-4">
														<div className="form-group">
															<label htmlFor="title">{i18n.t("Title")}</label>
															<input
																type="text"
																id="title"
																name="title"
																value={this.state.title}
																onChange={this.handleChange}
																className="form-control"
																required
															/>
														</div>

														<div className="form-group">
															<label htmlFor="description">{i18n.t("Description")}</label>
															<input
																type="text"
																id="description"
																name="description"
																value={this.state.description}
																onChange={this.handleChange}
																className="form-control"
																required
															/>
														</div>

														<p className="mt-5 p-0 mb-0">{i18n.t("For machine uses (required)")}</p>
														<div className="d-flex justify-content-around mt-3 mb-2">
															<div className="form-group">
																<label>
																	<input
																		type="checkbox"
																		id="washerUse"
																		name="washerUse"
																		checked={this.state.washerUse}
																		onChange={() => this.setState({
																			...this.state,
																			washerUse: !this.state.washerUse
																		})}
																	/>
																	<span>{i18n.t("Washer Use")}</span>
																</label>
															</div>

															<div className="form-group">
																<label>
																	<input
																		type="checkbox"
																		id="dryerUse"
																		name="dryerUse"
																		checked={this.state.dryerUse}
																		onChange={() => this.setState({
																			...this.state,
																			dryerUse: !this.state.dryerUse
																		})}
																	/>
																	<span>{i18n.t("Dryer Use")}</span>
																</label>
															</div>

															<div className="form-group">
																<label>
																	<input
																		type="checkbox"
																		id="packageUse"
																		name="packageUse"
																		checked={this.state.packageUse}
																		onChange={() => this.setState({
																			...this.state,
																			packageUse: !this.state.packageUse
																		})}
																	/>
																	<span>{i18n.t("Package Use")}</span>
																</label>
															</div>
														</div>

														<div className="form-group">
															<label htmlFor="duration">
																{i18n.t("Voucher Lifespan")} ({i18n.t("days")})
															</label>
															<input
																type="text"
																id="duration"
																name="duration"
																value={this.state.duration}
																onChange={this.handleChange}
																className="form-control"
																placeholder={i18n.t("leave blank if no expiry date")}
															/>
														</div>

														<div className="form-group">
															<label htmlFor="type">{i18n.t("Discount Type")}</label>
															<select
																id="type"
																name="type"
																className="browser-default form-select"
																required
																value={this.state.type}
																onChange={this.handleChange}
															>
																<option value="Flat">
																	{i18n.t("Flat")} ({currency(this.props.admin?.admin?.country || this.state.userCountry)})
																</option>
																<option value="Rate">{i18n.t("Rate")} (%)</option>
															</select>
														</div>

														<div className="form-group">
															<label htmlFor="amount">{i18n.t("Voucher Amount")}</label>
															<input
																type="number"
																id="amount"
																name="amount"
																className="form-control"
																value={this.state.amount}
																onChange={this.handleChange}
																min="0"
																step="0.01"
																required
															/>
														</div>

														<div className="form-group">
															<label htmlFor="remark">{i18n.t("Remarks")}</label>
															<select
																id="remark"
																name="remark"
																className="browser-default form-select"
																value={this.state.remark}
																onChange={this.handleChange}
																required
															>
																<option value="Reward">{i18n.t("Reward")}</option>
																<option value="Refund">{i18n.t("Refund")}</option>
															</select>
														</div>

														<div className="form-group">
															<Form.Group controlId="adminMessage" className="mt-3">
																<label htmlFor="Message">
																	{i18n.t("Message for User")}{" "}
																	{!this.state.emailVerified ? (
																		<small>
																			({i18n.t("SMS will send to user, please enter the message less than 130 characters")})
																		</small>
																	) : null}
																</label>
																<Form.Control
																	as="textarea"
																	rows={3}
																	id="adminMessage"
																	value={this.state.adminMessage}
																	onChange={this.handleChange}
																	placeholder={i18n.t("Enter the message to be sent to the user")}
																	required
																/>
															</Form.Group>
														</div>

														{this.state.emailVerified && (
															<>
																<div className="form-group">
																	<Form.Group controlId="adminEmail" className="mt-3">
																		<label htmlFor="Email">{i18n.t("Email Address to show on Email Template")}</label>
																		<Form.Control
																			type="email"
																			id="adminEmail"
																			value={this.state.adminEmail}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Email Address")}
																			required
																		/>
																	</Form.Group>
																</div>

																<div className="form-group">
																	<Form.Group controlId="adminCompanyName" className="mt-3">
																		<label htmlFor="CompanyName">{i18n.t("Company Name to show on Email Template")}</label>
																		<Form.Control
																			type="text"
																			id="adminCompanyName"
																			value={this.state.adminCompanyName}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Name")}
																		/>
																	</Form.Group>
																</div>

																<div className="form-group">
																	<Form.Group controlId="adminCompanyPhoneNo" className="mt-3">
																		<label htmlFor="CompanyPhoneNo">{i18n.t("Company Phone No. to show on Email Template")}</label>
																		<Form.Control
																			type="text"
																			id="adminCompanyPhoneNo"
																			value={this.state.adminCompanyPhoneNo}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Phone No.")}
																			required
																		/>
																	</Form.Group>
																</div>
																<button type="button" className="btn btn-secondary" onClick={this.toggleEmailPreview}>
																	{i18n.t("Preview Email")}
																</button>
															</>
														)}
													</div>

													{this.state.loading ? (
														<Loading />
													) : (
														<div className="d-flex justify-content-center">
															<div>
																<button type="submit" className="btn btn-primary">
																	{i18n.t("OK")}
																</button>
															</div>
															<div className="ms-3">
																<button
																	type="button"
																	className="btn btn-secondary"
																	onClick={() => this.setState({ voucherModal: false })}
																>
																	{i18n.t("Cancel")}
																</button>
															</div>
														</div>
													)}
												</form>
											</div>
										</Modal.Body>
									</Modal>

									{/* Stamp Modal */}
									<Modal
										show={this.state.stampModal}
										onHide={() => this.setState({ stampModal: false })}
										keyboard={false}
										centered
									>
										<Modal.Header closeButton>
											<Modal.Title>{i18n.t("STAMP")}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div>
												<form onSubmit={this.giveStamp}>
													<div className="text-left mt-4">
														<div className="form-group">
															<label htmlFor="washerStamp">{i18n.t("Washer Stamp")}</label>
															<input
																type="number"
																id="washerStamp"
																name="washerStamp"
																value={this.state.washerStamp}
																onChange={this.handleChange}
																className="form-control"
																min="0"
																max="10"
																step="1"
															/>
														</div>

														<div className="form-group">
															<label htmlFor="dryerStamp">{i18n.t("Dryer Stamp")}</label>
															<input
																type="number"
																id="dryerStamp"
																name="dryerStamp"
																value={this.state.dryerStamp}
																onChange={this.handleChange}
																className="form-control"
																min="0"
																max="10"
																step="1"
															/>
														</div>

														<div className="form-group">
															<label htmlFor="remark">{i18n.t("Remarks")}</label>
															<select
																id="remark"
																name="remark"
																className="browser-default form-select"
																value={this.state.remark}
																onChange={this.handleChange}
																required
															>
																<option value="Reward">{i18n.t("Reward")}</option>
																<option value="Refund">{i18n.t("Refund")}</option>
															</select>
														</div>

														<div className="form-group">
															<Form.Group controlId="adminMessage" className="mt-3">
																<label htmlFor="Message">
																	{i18n.t("Message for User")}{" "}
																	{!this.state.emailVerified ? (
																		<small>
																			({i18n.t("SMS will send to user, please enter the message less than 130 characters")})
																		</small>
																	) : null}
																</label>
																<Form.Control
																	as="textarea"
																	id="adminMessage"
																	rows={3}
																	value={this.state.adminMessage}
																	onChange={this.handleChange}
																	placeholder={i18n.t("Enter the message to be sent to the user")}
																	required
																/>
															</Form.Group>
														</div>

														{this.state.emailVerified && (
															<>
																<div className="form-group">
																	<Form.Group controlId="adminEmail" className="mt-3">
																		<label htmlFor="Email">{i18n.t("Email Address to show on Email Template")}</label>
																		<Form.Control
																			type="email"
																			id="adminEmail"
																			value={this.state.adminEmail}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Email Address")}
																			required
																		/>
																	</Form.Group>
																</div>

																<div className="form-group">
																	<Form.Group controlId="adminCompanyName" className="mt-3">
																		<label htmlFor="CompanyName">{i18n.t("Company Name to show on Email Template")}</label>
																		<Form.Control
																			type="text"
																			id="adminCompanyName"
																			value={this.state.adminCompanyName}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Name")}
																		/>
																	</Form.Group>
																</div>

																<div className="form-group">
																	<Form.Group controlId="adminCompanyPhoneNo" className="mt-3">
																		<label htmlFor="CompanyPhoneNo">{i18n.t("Company Phone No. to show on Email Template")}</label>
																		<Form.Control
																			type="text"
																			id="adminCompanyPhoneNo"
																			value={this.state.adminCompanyPhoneNo}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Phone No.")}
																			required
																		/>
																	</Form.Group>
																</div>

																<button type="button" className="btn btn-secondary" onClick={this.toggleEmailPreview}>
																	{i18n.t("Preview Email")}
																</button>
															</>
														)}
													</div>

													{this.state.loading ? (
														<Loading />
													) : (
														<div className="d-flex justify-content-center">
															<div>
																<button type="submit" className="btn btn-primary">
																	{i18n.t("OK")}
																</button>
															</div>
															<div className="ms-3">
																<button
																	type="button"
																	className="btn btn-secondary"
																	onClick={() => this.setState({ stampModal: false })}
																>
																	{i18n.t("Cancel")}
																</button>
															</div>
														</div>
													)}
												</form>
											</div>
										</Modal.Body>
									</Modal>

									{/* Point Modal */}
									<Modal
										show={this.state.pointModal}
										onHide={() => this.setState({ pointModal: false })}
										keyboard={false}
										centered
									>
										<Modal.Header closeButton>
											<Modal.Title>{i18n.t("POINT")}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div>
												<form onSubmit={this.givePoint}>
													<div className="text-left mt-4">
														<div className="form-group">
															<label htmlFor="pointAmount">{i18n.t("Number of Point")}</label>
															<input
																type="number"
																id="pointAmount"
																name="pointAmount"
																value={this.state.pointAmount}
																onChange={this.handleChange}
																className="form-control"
																min="0"
																step="1"
																required
															/>
														</div>

														<div className="form-group">
															<label htmlFor="remark">{i18n.t("Remarks")}</label>
															<select
																id="remark"
																name="remark"
																className="browser-default form-select"
																value={this.state.remark}
																onChange={this.handleChange}
																required
															>
																<option value="Reward">{i18n.t("Reward")}</option>
																<option value="Refund">{i18n.t("Refund")}</option>
															</select>
														</div>

														<div className="form-group">
															<Form.Group controlId="adminMessage" className="mt-3">
																<label htmlFor="Message">
																	{i18n.t("Message for User")}{" "}
																	{!this.state.emailVerified ? (
																		<small>
																			({i18n.t("SMS will send to user, please enter the message less than 130 characters")})
																		</small>
																	) : null}
																</label>
																<Form.Control
																	as="textarea"
																	id="adminMessage"
																	rows={3}
																	value={this.state.adminMessage}
																	onChange={this.handleChange}
																	placeholder={i18n.t("Enter the message to be sent to the user")}
																	required
																/>
															</Form.Group>
														</div>

														{this.state.emailVerified && (
															<>
																<div className="form-group">
																	<Form.Group controlId="adminEmail" className="mt-3">
																		<label htmlFor="Email">{i18n.t("Email Address to show on Email Template")}</label>
																		<Form.Control
																			type="email"
																			id="adminEmail"
																			value={this.state.adminEmail}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Email Address")}
																			required
																		/>
																	</Form.Group>
																</div>

																<div className="form-group">
																	<Form.Group controlId="adminCompanyName" className="mt-3">
																		<label htmlFor="CompanyName">{i18n.t("Company Name to show on Email Template")}</label>
																		<Form.Control
																			type="text"
																			id="adminCompanyName"
																			value={this.state.adminCompanyName}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Name")}
																		/>
																	</Form.Group>
																</div>

																<div className="form-group">
																	<Form.Group controlId="adminCompanyPhoneNo" className="mt-3">
																		<label htmlFor="CompanyPhoneNo">{i18n.t("Company Phone No. to show on Email Template")}</label>
																		<Form.Control
																			type="text"
																			id="adminCompanyPhoneNo"
																			value={this.state.adminCompanyPhoneNo}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Phone No.")}
																			required
																		/>
																	</Form.Group>
																</div>

																<button type="button" className="btn btn-secondary" onClick={this.toggleEmailPreview}>
																	{i18n.t("Preview Email")}
																</button>
															</>
														)}
													</div>

													{this.state.loading ? (
														<Loading />
													) : (
														<div className="d-flex justify-content-center">
															<div>
																<button type="submit" className="btn btn-primary">
																	{i18n.t("OK")}
																</button>
															</div>
															<div className="ms-3">
																<button
																	type="button"
																	className="btn btn-secondary"
																	onClick={() => this.setState({ pointModal: false })}
																>
																	{i18n.t("Cancel")}
																</button>
															</div>
														</div>
													)}
												</form>
											</div>
										</Modal.Body>
									</Modal>

									{/* Token Modal */}
									<Modal
										show={this.state.tokenModal}
										onHide={() => this.setState({ tokenModal: false })}
										keyboard={false}
										centered
									>
										<Modal.Header closeButton>
											<Modal.Title>{i18n.t("E-TOKEN")}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div>
												<form onSubmit={this.giveToken}>
													<div className="text-left mt-4">
														<div className="form-group">
															<label htmlFor="tokenAmount">{i18n.t("Number of e-Token")}</label>
															<input
																type="number"
																id="tokenAmount"
																name="tokenAmount"
																value={this.state.tokenAmount}
																onChange={this.handleChange}
																className="form-control"
																min="0"
																step="0.01"
																required
															/>
														</div>

														<div className="form-group">
															<label htmlFor="remark">{i18n.t("Remarks")}</label>
															<select
																id="remark"
																name="remark"
																className="browser-default form-select"
																value={this.state.remark}
																onChange={this.handleChange}
																required
															>
																<option value="Reward">{i18n.t("Reward")}</option>
																<option value="Refund">{i18n.t("Refund")}</option>
															</select>
														</div>

														<div className="form-group">
															<Form.Group controlId="adminMessage" className="mt-3">
																<label htmlFor="Message">
																	{i18n.t("Message for User")}{" "}
																	{!this.state.emailVerified ? (
																		<small>
																			({i18n.t("SMS will send to user, please enter the message less than 130 characters")})
																		</small>
																	) : null}
																</label>
																<Form.Control
																	as="textarea"
																	rows={3}
																	id="adminMessage"
																	value={this.state.adminMessage}
																	onChange={this.handleChange}
																	placeholder={i18n.t("Enter the message to be sent to the user")}
																	required
																/>
															</Form.Group>
														</div>

														{this.state.emailVerified && (
															<>
																<div className="form-group">
																	<Form.Group controlId="adminEmail" className="mt-3">
																		<label htmlFor="Email">{i18n.t("Email Address to show on Email Template")}</label>
																		<Form.Control
																			type="email"
																			id="adminEmail"
																			value={this.state.adminEmail}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Email Address")}
																			required
																		/>
																	</Form.Group>
																</div>

																<div className="form-group">
																	<Form.Group controlId="adminCompanyName" className="mt-3">
																		<label htmlFor="CompanyName">{i18n.t("Company Name to show on Email Template")}</label>
																		<Form.Control
																			type="text"
																			id="adminCompanyName"
																			value={this.state.adminCompanyName}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Name")}
																		/>
																	</Form.Group>
																</div>

																<div className="form-group">
																	<Form.Group controlId="adminCompanyPhoneNo" className="mt-3">
																		<label htmlFor="CompanyPhoneNo">{i18n.t("Company Phone No. to show on Email Template")}</label>
																		<Form.Control
																			type="text"
																			id="adminCompanyPhoneNo"
																			value={this.state.adminCompanyPhoneNo}
																			onChange={this.handleChange}
																			placeholder={i18n.t("Input Company Phone No.")}
																			required
																		/>
																	</Form.Group>
																</div>

																<button type="button" className="btn btn-secondary" onClick={this.toggleEmailPreview}>
																	{i18n.t("Preview Email")}
																</button>
															</>
														)}
													</div>

													{this.state.loading ? (
														<Loading />
													) : (
														<div className="d-flex justify-content-center">
															<div>
																<button type="submit" className="btn btn-primary">
																	{i18n.t("OK")}
																</button>
															</div>
															<div className="ms-3">
																<button
																	type="button"
																	className="btn btn-secondary"
																	onClick={() => this.setState({ tokenModal: false })}
																>
																	{i18n.t("Cancel")}
																</button>
															</div>
														</div>
													)}
												</form>
											</div>
										</Modal.Body>
									</Modal>

									{/* Email Preview Modal */}
									<Modal
										show={this.state.showEmailPreview}
										onHide={this.toggleEmailPreview}
										keyboard={false}
										centered
									>
										<Modal.Header closeButton>
											<Modal.Title>{i18n.t("Email Preview")}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div dangerouslySetInnerHTML={{ __html: this.generateEmailPreview() }} />
										</Modal.Body>
										<Modal.Footer>
											<button type="button" className="btn btn-secondary" onClick={this.toggleEmailPreview}>
												{i18n.t("Close")}
											</button>
										</Modal.Footer>
									</Modal>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(UserDetails);
