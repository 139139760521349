import crypto from 'crypto'

// ----- to control all the dynamics components ----- //
// some function might be same, but I kept it separated so it can be changed next time
import logo1 from "./assets/images/logo/logo1.png";
import laundroLogo1 from "./assets/images/logo/laundroLogo1.png";
import laundroEmailLogo from "./assets/images/logo/laundroEmailLogo.png";
import mainLogo from "./assets/images/logo/mainLogo.png";
import laundroLogo from "./assets/images/logo/laundroLogo.png";
import washStudioLogo from "./assets/images/logo/washStudioLogo.png";
import washStudioLogo1 from "./assets/images/logo/washStudioLogo1.png";
import bubblesLogo from "./assets/images/logo/bubblesLogo.png";
import bubblesLogo1 from "./assets/images/logo/bubblesLogo1.png";
import washupLogo from "./assets/images/logo/washupLogo.png";
import washupLogo1 from "./assets/images/logo/washupLogo1.png";
// import cleanproLogo from "./assets/images/logo/cleanproqrlogo.png";
import cleanproLogo1 from "./assets/images/logo/cleanproExpressLogo.png";
import cleanproPlusLogo from "./assets/images/logo/Logo_Cleanproplus-white.png";
import aerosparkleLogo from "./assets/images/logo/aerosparkleLogo.png";

import laundroStep1 from "./assets/images/faq/Step1.png";
import laundroStep2 from "./assets/images/faq/Step2-1.png";
import laundroStep3 from "./assets/images/faq/Step2-2.png";
import laundroStep4 from "./assets/images/faq/Step3.png";
import laundroStep5 from "./assets/images/faq/Step4.png";
import laundroStep6 from "./assets/images/faq/Step5.png";
import cuciStep1 from "./assets/images/faq/CuciStep1.png";
import cuciStep2 from "./assets/images/faq/CuciStep2-1.png";
import cuciStep3 from "./assets/images/faq/CuciStep2-2.png";
import cuciStep4 from "./assets/images/faq/CuciStep3.png";
import cuciStep5 from "./assets/images/faq/CuciStep4.png";
import cuciStep6 from "./assets/images/faq/CuciStep5.png";
import bubblesStep1 from "./assets/images/faq/BubblesStep1.png";
import washStudioStep1 from "./assets/images/faq/WashStudioStep1.png";
import washupStep1 from "./assets/images/faq/washupStep1.png";
import washupStep2 from "./assets/images/faq/washupStep2-1.png";
import washupStep3 from "./assets/images/faq/washupStep2-2.png";
import washupStep4 from "./assets/images/faq/washupStep3.png";
import washupStep5 from "./assets/images/faq/washupStep4.png";
import washupStep6 from "./assets/images/faq/washupStep5.png";
import cleanproStep1 from "./assets/images/faq/cleanproStep1.png";
import cleanproStep2 from "./assets/images/faq/cleanproStep2-1.png";
import cleanproStep3 from "./assets/images/faq/cleanproStep2-2.png";
import cleanproStep4 from "./assets/images/faq/cleanproStep3.png";
import cleanproStep5 from "./assets/images/faq/cleanproStep4.png";
import cleanproStep6 from "./assets/images/faq/cleanproStep5.png";
import reloadFaq1 from "./assets/images/faq/reloadFaq1.png";
import reloadFaq2 from "./assets/images/faq/reloadFaq2.png";
import reloadFaq3 from "./assets/images/faq/reloadFaq3.png";
import cuciReloadFaq1 from "./assets/images/faq/cuciReloadFaq1.png";
import cuciReloadFaq2 from "./assets/images/faq/cuciReloadFaq2.png";
import cuciReloadFaq3 from "./assets/images/faq/cuciReloadFaq3.png";
import washupReload1 from "./assets/images/faq/washupReload1.png";
import washupReload2 from "./assets/images/faq/washupReload2.png";
import washupReload3 from "./assets/images/faq/washupReload3.png";
import cleanproReload1 from "./assets/images/faq/cleanproReload1.png";
import cleanproReload2 from "./assets/images/faq/cleanproReload2.png";
import cleanproRelaod3 from "./assets/images/faq/cleanproReload3.png";
import noVoucher from "./assets/images/faq/noVoucher.png";
import cuciNoVoucher from "./assets/images/faq/cuciNoVoucher.png";
import washupNoVoucher from "./assets/images/faq/washupNoVoucher.png";
import cleanproNoVoucher from "./assets/images/faq/cleanproNoVoucher.png";
import voucher1 from "./assets/images/faq/voucher1.png";
import voucher2 from "./assets/images/faq/voucher2.png";
import voucher3 from "./assets/images/faq/voucher3.png";
import cuciVoucher1 from "./assets/images/faq/cuciVoucher1.png";
import cuciVoucher2 from "./assets/images/faq/cuciVoucher2.png";
import cuciVoucher3 from "./assets/images/faq/cuciVoucher3.png";
import washupVoucher1 from "./assets/images/faq/washupVoucher1.png";
import washupVoucher2 from "./assets/images/faq/washupVoucher2.png";
import washupVoucher3 from "./assets/images/faq/washupVoucher3.png";
import cleanproVoucher1 from "./assets/images/faq/cleanproVoucher1.png";
import cleanproVoucher2 from "./assets/images/faq/cleanproVoucher2.png";
import cleanproVoucher3 from "./assets/images/faq/cleanproVoucher3.png";
import cleanproPlusBear from "./assets/images/logo/img-bombom-signup.png";

const laundroFaq = [
  laundroStep1,
  laundroStep2,
  laundroStep3,
  laundroStep4,
  laundroStep5,
  laundroStep6,
  reloadFaq1,
  reloadFaq2,
  reloadFaq3,
  noVoucher,
  voucher1,
  voucher2,
  voucher3,
];
const cuciFaq = [
  cuciStep1,
  cuciStep2,
  cuciStep3,
  cuciStep4,
  cuciStep5,
  cuciStep6,
  cuciReloadFaq1,
  cuciReloadFaq2,
  cuciReloadFaq3,
  cuciNoVoucher,
  cuciVoucher1,
  cuciVoucher2,
  cuciVoucher3,
];
const bubblesFaq = [
  bubblesStep1,
  laundroStep2,
  laundroStep3,
  laundroStep4,
  laundroStep5,
  laundroStep6,
  reloadFaq1,
  reloadFaq2,
  reloadFaq3,
  noVoucher,
  voucher1,
  voucher2,
  voucher3,
];
const washStudioFaq = [
  washStudioStep1,
  laundroStep2,
  laundroStep3,
  laundroStep4,
  laundroStep5,
  laundroStep6,
  reloadFaq1,
  reloadFaq2,
  reloadFaq3,
  noVoucher,
  voucher1,
  voucher2,
  voucher3,
];
const washupFaq = [
  washupStep1,
  washupStep2,
  washupStep3,
  washupStep4,
  washupStep5,
  washupStep6,
  washupReload1,
  washupReload2,
  washupReload3,
  washupNoVoucher,
  washupVoucher1,
  washupVoucher2,
  washupVoucher3,
];
const cleanproFaq = [
  cleanproStep1,
  cleanproStep2,
  cleanproStep3,
  cleanproStep4,
  cleanproStep5,
  cleanproStep6,
  cleanproReload1,
  cleanproReload2,
  cleanproRelaod3,
  cleanproNoVoucher,
  cleanproVoucher1,
  cleanproVoucher2,
  cleanproVoucher3,
];

// to avoid typo, assign to variable
const STORETYPE = process.env.REACT_APP_STORETYPE;
const ENCRYPT_KEY = process.env.REACT_APP_ENCRYPT_KEY;
const AES_IV = process.env.REACT_APP_IV;

const cuci = "cuci";
const laundro = "laundro";
const washStudio = "washStudio";
const bubbles = "bubbles";
const washup = "washup";
const cleanpro = "cleanpro";
const aerosparkle = "aerosparkle";

export const encrypt = (text) => {
  const cipher = crypto.createCipheriv('aes-128-cbc', ENCRYPT_KEY, AES_IV)
  const encrypted = Buffer.concat([cipher.update(JSON.stringify(text)), cipher.final()]).toString("base64")
  return encrypted
}

export const decrypt = (text) => {
  const decryptCipher = crypto.createDecipheriv('aes-128-cbc', ENCRYPT_KEY, AES_IV)
  const decryptResponse = Buffer.concat([decryptCipher.update(Buffer.from(text, "base64")), decryptCipher.final()]).toString("utf8")
  const decryptResponseJson = JSON.parse(decryptResponse)
  return decryptResponseJson
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Malaysia: RM, Taiwan: NT$
export const currency = (country) => {
  if (country === "Brazil") {
    return "R$"
  } else if (country === "Thailand") {
    return "฿"
  } else if (country === "Singapore") {
    return "S$"
  } else if (country === "Taiwan") {
    return "NT$"
  } else {
    return "RM"
  }
}

export const carelineNumber = (country) => {
  if (country === "Thailand") {
    return "02-026-3699"
  } else {
    // Malaysia
    return "+603-2770-0100"
  }
}

export const languageSelection = [
  { value: "en", title: "English" },
  { value: "ms", title: "Malay" },
  { value: "zh", title: "Chinese (Simplified)" },
  { value: "zhtw", title: "Chinese (Traditional)" },
  { value: "th", title: "Thai" },
]

// format date to yyyy-mm-dd
export const dateFormat = (date) => {
  const year = new Date(date).getFullYear()
  let month = new Date(date).getMonth() + 1
  let day = new Date(date).getDate()

  if (month.toString().length < 2) month = `0${month}`;
  if (day.toString().length < 2) day = `0${day}`;

  return `${year}-${month}-${day}`;
}

export const timeFormat = (date) => {
  let hour = new Date(date).getHours()
  let minute = new Date(date).getMinutes()
  let second = new Date(date).getSeconds()

  if (hour.toString().length < 2) hour = `0${hour}`;
  if (minute.toString().length < 2) minute = `0${minute}`;
  if (second.toString().length < 2) second = `0${second}`;

  return `${hour}${minute}${second}`;
}

// admin sidebar
export const logo1Controller = (themeLogo) => {
  if (STORETYPE === cuci) return logo1;
  else {
    if (themeLogo === "laundroLogo") {
      if (STORETYPE === laundro) return laundroLogo1;
      else if (STORETYPE === washStudio) return washStudioLogo1;
      else if (STORETYPE === bubbles) return bubblesLogo1;
      else if (STORETYPE === washup) return washupLogo1;
      else if (STORETYPE === cleanpro) return cleanproLogo1;
      else if (STORETYPE === aerosparkle) return aerosparkleLogo;
      else return laundroLogo1;
    } else return themeLogo;
  }
};

// admin login page, user landing page, user sidebar
export const logoController = (themeLogo) => {
  if (STORETYPE === cuci) return mainLogo;
  else {
    if (themeLogo === "laundroLogo") {
      if (STORETYPE === laundro) return laundroLogo;
      else if (STORETYPE === washStudio) return washStudioLogo;
      else if (STORETYPE === bubbles) return bubblesLogo;
      else if (STORETYPE === washup) return washupLogo;
      else if (STORETYPE === cleanpro) return cleanproPlusBear;
      else if (STORETYPE === aerosparkle) return aerosparkleLogo;
      else return laundroLogo;
    } else return themeLogo;
  }
};

// logo for email template
export const emailLogo = () => {
  if (STORETYPE === cuci) return logo1;
  else if (STORETYPE === washStudio) return washStudioLogo1;
  else if (STORETYPE === bubbles) return bubblesLogo1;
  else if (STORETYPE === washup) return washupLogo1;
  else if (STORETYPE === cleanpro) return cleanproPlusLogo;
  else if (STORETYPE === aerosparkle) return aerosparkleLogo;
  else return laundroEmailLogo;
};

// default theme if local storage do not have operatorId
export const defaultTheme = () => {
  if (STORETYPE === washup) return "washup-theme";
  else if (STORETYPE === cleanpro) return "cleanpro-theme";
  else if (STORETYPE === aerosparkle) return "lightBlue-theme";
  else return "";
};

// user navigator classNames
export const userNavigatorClassNames = (nav, val) => {
  if (STORETYPE === cuci) {
    if (val === nav) return "nav-tab waves-effect waves-yellow selected-nav";
    else return "nav-tab waves-effect waves-yellow";
  } else if (
    STORETYPE === laundro ||
    STORETYPE === washStudio ||
    STORETYPE === bubbles ||
    STORETYPE === washup ||
    STORETYPE === cleanpro ||
    STORETYPE === aerosparkle
  ) {
    if (val === nav) return "nav-tab waves-effect selected-nav";
    else return "nav-tab waves-effect";
  } else {
    return "";
  }
};

// user landing page small title
export const smallTitle = () => {
  if (STORETYPE === laundro) return "Laundro Loyalty";
  else if (STORETYPE === washStudio) return "MyWashStudio Loyalty";
  else if (STORETYPE === bubbles) return "BubblesPulse Loyalty";
  else if (STORETYPE === washup) return "Octopus Loyalty";
  else if (STORETYPE === cleanpro) return "Cleanpro Pay";
  else if (STORETYPE === aerosparkle) return "Aerosparkle Loyalty";
  else return null;
};

// distributor redirect laundro url
export const distributorRedirectUrl = () => {
  if (STORETYPE === washStudio)
    return `https://mywashstudio.com/franchisorYearlyOverview`;
  else if (STORETYPE === bubbles)
    return `https://bubblespulse.com.my/franchisorYearlyOverview`;
  else if (STORETYPE === cuci)
    return `https://laundro.cuciexpress.asia/franchisorYearlyOverview`;
  else if (STORETYPE === washup)
    return `https://laundro.octopus-laundry.com/franchisorYearlyOverview`;
  else if (STORETYPE === cleanpro)
    return `https://cleanprolink.cleanproexpress.com/franchisorYearlyOverview`;
  else return `https://thelaundro.com/franchisorYearlyOverview`;
};

// admin redirect laundro url
export const laundroRedirectUrl = () => {
  if (STORETYPE === washStudio) return `https://mywashstudio.com/mainDashboard`;
  else if (STORETYPE === bubbles)
    return `https://bubblespulse.com.my/mainDashboard`;
  else if (STORETYPE === cuci)
    return `https://laundro.cuciexpress.asia/mainDashboard`;
  else if (STORETYPE === washup)
    return `https://laundro.octopus-laundry.com/mainDashboard`;
  else if (STORETYPE === cleanpro)
    return `https://cleanprolink.cleanproexpress.com/mainDashboard`;
  else return `https://thelaundro.com/mainDailyDashboard`;
};

// staff redirect laundro url
export const staffRedirectUrl = () => {
  if (STORETYPE === washStudio) return `https://mywashstudio.com/transactions_record`;
  else if (STORETYPE === bubbles)
    return `https://bubblespulse.com.my/transactions_record`;
  else if (STORETYPE === cuci)
    return `https://laundro.cuciexpress.asia/transactions_record`;
  else if (STORETYPE === washup)
    return `https://laundro.octopus-laundry.com/transactions_record`;
  else if (STORETYPE === cleanpro)
    return `https://cleanprolink.cleanproexpress.com/transactions_record`;
  else return `https://thelaundro.com/transactions_record`;
};

// admin redirect laundro text
export const laundroText = () => {
  if (STORETYPE === washStudio) return "MyWashStudio";
  else if (STORETYPE === bubbles) return "BubblesPulse";
  else if (STORETYPE === cuci) return "Cuci Express";
  else if (STORETYPE === washup) return "Octopus";
  else if (STORETYPE === cleanpro) return "Cleanpro";
  else if (STORETYPE === aerosparkle) return "Aerosparkle"
  else return "Laundro";
};

// default faq images
export const faqImage = () => {
  if (STORETYPE === washStudio) return washStudioFaq;
  else if (STORETYPE === bubbles) return bubblesFaq;
  else if (STORETYPE === cuci) return cuciFaq;
  else if (STORETYPE === washup) return washupFaq;
  else if (STORETYPE === cleanpro) return cleanproFaq;
  else return laundroFaq;
};

export const navCameraClassName =
  process.env.REACT_APP_STORETYPE === "cuci"
    ? "waves-effect waves-orange"
    : "waves-effect waves-blue";
